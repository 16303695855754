<template>
    <div class="dashboard-com-hours-driven">
        <com-layout-top class="com-hours-driven-layout">
            <template v-slot:name>
                <com-top-title :title="$t('dashboard.hoursDriven')" :unit="currentUnit">
                    <span class="via-hours ry-font"/>
                </com-top-title>
            </template>
            <template v-slot:btn>
                <com-btn :type="currentType" on-tag="hoursDriven" @hoursDriven="handleBtnChange"/>
            </template>
            <template v-slot:value v-if="currentType === 'avg'">
                <com-top-value :value="currentValue" :value-num="currentValueNum" style="width: 45%;"
                               class="ry-left"/>
                <com-fleet-info-list :data="currentData"
                                     :list-point="1"
                                     style="width: 55%;"
                                     class="ry-right"/>
            </template>
            <template v-slot:value v-else>
                <com-top-value :value="currentValue" :value-num="currentValueNum" style="width: 50%;"
                               class="ry-left"/>
                <com-chart-pie :data-and-colors="{ data: currentData, colors: currentColorList}" style="width: 50%;"
                               class="ry-right"/>
            </template>
        </com-layout-top>
    </div>
</template>

<script>
    import ComLayoutTop from './ComLayoutTop';
    import ComBtn from "./ComBtn";
    import ComTopTitle from './ComTopTitle';
    import ComTopValue from './ComTopValue';
    import ComChartPie from './ComChartPie';
    import ComFleetInfoList from './ComFleetInfoList';

    export default {
        name: "ComHoursDriven",
        props: {
            type: {
                type: String,
                default: 'total'
            },
            avg: {
                type: Array,
                required: true
            },
            total: {
                type: Array,
                required: true
            },
            avgValue: {
                // type: Number,
                required: true
            },
            avgValueNum: {
                type: Number,
                required: true
            },
            totalValue: {
                // type: Number,
                required: true
            },
            totalValueNum: {
                type: Number,
                required: true
            }
        },
        components: {
            ComLayoutTop,
            ComBtn,
            ComTopTitle,
            ComTopValue,
            ComChartPie,
            ComFleetInfoList
        },
        data() {
            return {
                avgData: [
                    // {value:1639, name:'fleet1'},
                ],
                avgOption: {
                    unit: 'Hrs / Trip',
                    value: 1300,
                },
                totalData: [
                    // {value:17269, name:'fleet1'},
                ],
                totalOption: {
                    unit: 'Hrs',
                    value: 1600,
                },
                title: 'Hours Driven',
                currentType: this.type
            }
        },
        computed: {
            currentUnit() {
                if (this.currentType === 'avg') {
                    return this.$t('units.hrsPerTrip');
                } else {
                    return this.$t('units.hrs');
                }
            },
            currentValue() {
                if (this.currentType === 'avg') {
                    return this.avgValue;
                } else {
                    return this.totalValue;
                }
            },
            currentValueNum() {
                if (this.currentType === 'avg') {
                    return this.avgValueNum;
                } else {
                    return this.totalValueNum;
                }
            },
            currentData() {
                if (this.currentType === 'avg') {
                    return this.avg;
                } else {
                    return this.total;
                }
            },
            currentColorList() {
                let colorList = [];
                this.currentData.forEach(item => {
                    colorList.push(item.color);
                });
                return colorList;
            }
        },
        methods: {
            handleBtnChange(type) {
                if (type === 'avg') {
                    this.currentType = 'avg';
                } else {
                    this.currentType = 'total';
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-title = $color_black_first
    $font-color-content = $color_black_first
    $font-color-value = $color_black_first

    .dashboard-com-hours-driven
        background-color $color_white
        height 100%

        .com-hours-driven-layout
            height 100%

        .com-hours-driven-left
            width 35%
            float left

        .com-hours-driven-right
            float left
            width 65%
</style>