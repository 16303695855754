<template>
    <div>
        <div class="dashboard-com-layout-top">
            <div class="com-layout-top-box">
                <div :class="[isCollisionType?'ry-left collision-left':'ry-left']" @click="onClickAlertTab('collisionType')">
                    <slot name="name"></slot>
                </div>
                <div :class="[isCollisionType?'ry-right collision-right':'ry-right']" @click="onClickAlertTab('parkingType')">
                    <slot name="btn"></slot>
                </div>
            </div>
            <div class="ry-bottom">
                <!--<div class="ry-left">-->
                <slot name="value"></slot>
                <!--</div>-->
                <!--<div class="ry-right">-->
                <!--<slot name="list"></slot>-->
                <!--</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ComLayoutTop",
        props:{
            isCollisionType:{
                Type:Boolean,
                default:false
            }
        },
        methods:{
            onClickAlertTab(tabName){
                if (this.isCollisionType) {
                    this.$emit("onClickCollision", tabName);
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    $min-width = 100px

    .ry-bottom .ry-left {
        width calc(100% - 190px) !important

    }

    .ry-bottom .ry-right {
        width 190px !important
    }
    .ry-bottom .ry-right-list{
        margin-right 50px
    }

    @media only screen and (max-width: 1830px) {
        .ry-bottom .ry-left {
            float none !important
            display block
            text-align center
            width 100% !important
            padding-top 15px
            height 45px !important
        }

        .ry-bottom .ry-right {
            width 100% !important
            height 160px !important
        }

        >>> .dashboard-com-fleet-info-list .com-fleet-info-list-table {
            width 100% !important
        }

        >>> .dashboard-com-fleet-info-list .com-fleet-info-list-table .com-fleet-info-list-row .com-fleet-info-list-left {
            width calc(100% - 60px) !important
        }

        >>> .dashboard-com-fleet-info-list .com-fleet-info-list-table .com-fleet-info-list-row .com-fleet-info-list-right {
            width 60px !important
        }
    }

    @media only screen and (max-width: 1600px) {
        .ry-bottom_bak .ry-left {
            width 40% !important
        }

        .ry-bottom_bak .ry-right {
            width 60% !important
        }
    }

    @media only screen and (max-width: 1440px) {
        .ry-bottom_bak .ry-left {
            width 30% !important
            overflow hidden
        }

        .ry-bottom_bak .ry-right {
            width 70% !important
        }

        .ry-bottom_bak >>> .dashboard-com-fleet-info-list .com-fleet-info-list-row .com-fleet-info-list-left .com-fleet-info-list-block {
            width 14px !important
        }
    }

    @media only screen and (max-width: 1360px) {
        .ry-bottom_bak .ry-left {
            width 0% !important
            overflow hidden
        }

        .ry-bottom_bak .ry-right {
            width 100% !important
        }
    }

    .dashboard-com-layout-top
        height 100%
        min-width $min-width

        .com-layout-top-box
            min-width $min-width
            height 30px

            .ry-left
                width calc(100% - 80px)
                display inline-block

            .ry-right
                width 80px
                float right

            .collision-left
                width calc(100% - 50%)!important

            .collision-right
                width calc(100% - 50%)!important

        .ry-bottom
            min-width $min-width
            height calc(100% - 40px)

            .ry-left, .ry-right
                height 100%
                float left

</style>
