<template>
    <div>
        <div class="dashboard-btn-switch">
            <div class="dbw-total" @click="handleBtnChange('avg')" v-show="this.currentType === 'total'">
                <div class="dbw-left dbw-active"><span class="dbw-text">{{$t('dashboard.total')}}</span></div>
                <div class="dbw-right dbw-not-active"><span class="dbw-text">{{$t('dashboard.avg')}}</span></div>
            </div>
            <div class="dbw-avg" @click="handleBtnChange('total')" v-show="this.currentType !== 'total'">
                <div class="dbw-left dbw-not-active"><span class="dbw-text">{{$t('dashboard.total')}}</span></div>
                <div class="dbw-right dbw-active"><span class="dbw-text">{{$t('dashboard.avg')}}</span></div>
            </div>
        </div>
<!--        <div class="dashboard-com-btn">-->
<!--            <img @click="handleBtnChange('avg')" v-if="this.currentType === 'total'"-->
<!--                 src="../../assets/image/Total.svg" height="18" width="82"/>-->
<!--            <img @click="handleBtnChange('total')" v-else src="../../assets/image/Avg.svg"-->
<!--                 height="18" width="82"/>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "ComBtn",
        props: {
            type: {
                type: String,
                default: 'avg'
            },
            onTag: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                currentType: this.type
            }
        },
        methods: {
            handleBtnChange(value) {
                this.currentType = value;
                this.$emit(this.onTag, value);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .dashboard-btn-switch
        width 70px
        cursor pointer
        -moz-user-select: none; /*火狐*/
        -webkit-user-select: none; /*webkit浏览器*/
        -ms-user-select: none; /*IE10*/
        -khtml-user-select: none; /*早期浏览器*/
        user-select: none;

        .dbw-total, .dbw-avg
            display flex

        .dbw-text
            line-height 18px
            font-size 12px
            width 41px
            height 18px

        .dbw-active
            background-color $color_blue_second

            .dbw-text
                color #ffffff

        .dbw-not-active
            background-color $color_gray_third

            .dbw-text
                color $color_black_third

        .dbw-left, .dbw-right
            width 50%

        .dbw-left
            border-bottom-left-radius 9px
            border-top-left-radius 9px
            text-align right

            .dbw-text
                padding-right 3px

        .dbw-right
            border-bottom-right-radius 9px
            border-top-right-radius 9px

            .dbw-text
                padding-left 3px

    .dashboard-com-btn
        cursor pointer
        position relative
        left -3px
</style>