<template>
    <div class="dashboard-com-chart-pie">
        <div class="com-pie-chart" ref="ryChartBox"></div>
    </div>
</template>

<script>
    import bus from "../../utils/bus";
    import echarts from 'echarts/lib/echarts';
    import 'echarts/lib/chart/pie';
    import 'echarts/lib/component/tooltip';
    import 'echarts/lib/component/legend';
    import 'echarts/lib/component/legendScroll';
    import 'echarts/lib/component/grid';
    import 'echarts/lib/component/title';
    import {numRound} from "../../assets/js/Util";

    export default {
        name: "ComChartPie",
        props: {
            dataAndColors: {
                required: true,
                type: Object
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initChart();
            });
            bus.$on('chartResize', this.resize);
        },
        data() {
            return {
                chart: null,
                noData: false,
                colorsList: [
                    '#74aafd', '#4e66cf', '#ffe115', '#6aac21', '#b74983',
                    '#749f83', '#ca8622', '#bda29a',
                    '#6e7074', '#546570', '#c4ccd3'
                ],
                chartOption: {
                    animation: false,
                    color: [],
                    tooltip: {
                        trigger: 'item',
                        formatter_bak: "{b}: {c} ({d}%)",
                        formatter: (params) => {
                            let name = params.data.name,
                                value = params.data.value,
                                percent = params.percent;
                            value = numRound(value, 1);
                            percent = numRound(percent, 1);
                            return name + ': ' + value + ' (' + percent + '%)';
                        },
                        position: 'right',
                        transitionDuration:0.0,
                    },
                    label: {
                        fontSize: '20',
                        show: false
                    },
                    // legend: {
                    //     type: 'plain',
                    //     orient: 'vertical',
                    //     right: 0,
                    //     top: 'middle',
                    // },
                    series: [
                        {
                            type: 'pie',
                            radius: ['25%', '50%'],
                            center: ['50%', '50%'],
                            avoidLabelOverlap: false,
                            labelLine: {
                                normal: {
                                    show: false,
                                    // lineStyle: {
                                    //     color: 'rgba(0, 0, 0, 0)'
                                    // },
                                    smooth: 0.1,
                                    length: 12,
                                    length2: -15
                                }
                            },
                            label: {
                                show: true,
                                position: 'outer',
                                // formatter: '{d}%',
                                formatter: function (data) {
                                    if (data.percent < 5) return '';
                                    return data.percent.toFixed(0) + "%";
                                },
                                fontSize: 14,
                            },
                            data: this.data
                        }
                    ]
                },
            }
        },
        computed: {
            data() {
                return this.dataAndColors.data;
            },
            colors() {
                return this.dataAndColors.colors;
            }
        },
        watch: {
            data() {
                this.initChart();
            },
            colors() {
                this.initChart();
            }
        },
        methods: {
            resize() {
                if (this.chart) {
                    this.chart.resize();
                }
            },
            initChart() {
                this.chartOption.color = this.colors;
                this.chartOption.series[0].data = this.data;
                this.makeMap();
            },
            makeMap() {
                this.checkData();
                if (!this.chart) {
                    this.chart = echarts.init(this.$refs.ryChartBox);
                }
                this.chart.setOption(this.chartOption, true);
            },
            checkData() {
                if (!this.data || typeof this.data === 'string' || Object.keys(this.data).length === 0) {
                    this.noData = true;
                } else {
                    this.noData = false;
                }
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .dashboard-com-chart-pie
        height 100%

        .com-pie-chart
            width 100%
            height 100%
</style>
