<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="dashboard-com-hours-driven">
        <com-layout-top class="com-hours-driven-layout" isCollisionType="true" @onClickCollision="onClickCollision">
            <template v-slot:name >
                <com-top-title :title="$tc('dashboard.collisionAlerts', 2)" unit="Total" :isCollisionType="collisionType" :selected="isSelected">
                    <span :class="[isSelected ? 'via-car-crash icon-select-color' : 'via-car-crash icon-color']"></span>
                </com-top-title>
            </template>
            <template v-slot:btn :onClickCollision="onClickCollision" class="ry-font">
                <div>
                    <div v-if="collisionType">
                        <com-top-title :title="$tc('dashboard.parkingAlerts', 2)" unit="Total" :isCollisionType="collisionType" :selected="!isSelected">
                            <span :class="[!isSelected ? 'via-Parking-alert icon-select-color' : 'via-Parking-alert icon-color']"></span>
                        </com-top-title>
                    </div>
                    <div v-else></div>
                </div>

            </template>
            <template v-slot:value>
                <com-top-value :value-num="totalValueNum" :value="totalValue" :point="0" style="width: 45%;" class="ry-left"/>
                <com-fleet-info-list :data="total" style="width: 55%;" class="ry-right"/>
            </template>
        </com-layout-top>
    </div>
</template>

<script>
    import ComLayoutTop from './ComLayoutTop';
    import ComTopTitle from './ComTopTitle';
    import ComTopValue from './ComTopValue';
    import ComFleetInfoList from './ComFleetInfoList';

    export default {
        name: "ComCollisionAlerts",
        props: {
            total: {
                type: Array,
                required: true
            },
            totalValue: {
                required: true
            },
            totalValueNum: {
                type: Number,
                required: true
            },
            collisionType:{
                type:Boolean,
                default:false
            }
        },
        components: {
            ComLayoutTop,
            ComTopTitle,
            ComTopValue,
            ComFleetInfoList,
        },
        data(){
            return {
                isSelected:true
            }
        },
        methods:{
            onClickCollision(flag){
                this.isSelected = flag === 'collisionType';
                this.$emit("alertModel",flag)
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-title = $color_black_first
    $font-color-content = $color_black_first
    $font-color-value = $color_black_first
    $icon-active-color = $color_collision

    .dashboard-com-hours-driven
        background-color $color_white
        height 100%

        .com-hours-driven-layout
            height 100%

    .icon-color::before
        color #8c939d
        font-size 22px
        margin-right 3px
    .icon-select-color::before
        color $icon-active-color
        font-size 22px
        margin-right 3px
</style>
