<template>
    <div class="dashboard-com-ranking-title">
        <div class="com-ranking-title-main">
            <span class="com-ranking-title-img" @click="handleMore">
                <slot></slot>
            </span>
            <span class="com-ranking-title" @click="handleMore">{{ title }}</span>
            <span v-if="more" @click="handleMore" class="com-ranking-title-more">more...</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ComRankingTitle",
        props: {
            title: {
                type: String,
                required: true
            },
            more: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            handleMore() {
                this.$emit('more-action');
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-title = $color_black_first;
    $font-color-content = $color_black_first;

    .dashboard-com-ranking-title
        display block

        .com-ranking-title-main
            border-bottom 1px solid $color_gray_third
            padding 5px 0
            cursor default
            color: $color_black_first

            .com-ranking-title-img
                display inline-block
                margin-right 8px
                cursor pointer
                width 25px
                height 24px

                .ry-font
                    font-size 22px
                    position relative
                    top -3px

            .com-ranking-title
                position relative
                top -5px
                display inline-block
                font-weight 600
                font-size 16px
                cursor pointer

            .com-ranking-title-more
                display block
                float right
                color $font-color-content
                font-size 14px
                line-height 25px
                cursor pointer
        .com-ranking-title-main:hover
            color $color_blue_second
</style>
