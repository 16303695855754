<template>
    <div class="dashboard-com-total-chart">
        <div class="com-total-chart-main display-flex">
            <div class="com-total-chart-top">
                <com-chart-line
                        :title="$t('dashboard.totalHoursDriven') + ':'"
                        :start-date="startDate"
                        :end-date="endDate"
                        :value="hoursDrivenValue"
                        :type="hoursDrivenType"
                        :unit="$t('dashboard.hours')"
                        :unit-abbr="unitHoursAbbr"
                        :color="hours.color"
                        :data="hoursDriven"
                        :unit-choice="hoursUnitChoice">
                    <span class="via-hours ry-font"/>
                </com-chart-line>
            </div>
            <div class="com-total-chart-middle">
                <com-chart-line
                        :title="$t('dashboard.totalDistanceDriven') + ':'"
                        :start-date="startDate"
                        :end-date="endDate"
                        :value="distanceDrivenValue"
                        :type="distanceDrivenType"
                        :unit="unitKilometers"
                        :unit-abbr="unitKilometersAbbr"
                        :color="distance.color"
                        :data="distanceDriven"
                        :unit-choice="kilometersUnitChoice">
                    <span class="via-distance ry-font"/>
                </com-chart-line>
            </div>
            <div class="com-total-chart-bottom">
                <com-chart-line
                        :title="$t('dashboard.totalFuelConsumption') + ':'"
                        :start-date="startDate"
                        :end-date="endDate"
                        :value="fuelConsumptionValue"
                        :type="fuelConsumptionType"
                        :unit="unitLiters"
                        :unit-abbr="unitLitersAbbr"
                        :color="fuel.color"
                        :data="fuelConsumption"
                        :unit-choice="litersUnitChoice">
                    <span class="via-fuel ry-font"/>
                </com-chart-line>
            </div>
        </div>
    </div>
</template>

<script>
    import ComChartLine from './ComChartLine';

    export default {
        name: "ComTotalChart",
        props: {
            hoursDriven: {
                type: Array,
                required: true
            },
            hoursDrivenValue: {
                // type: Number,
                required: true
            },
            hoursDrivenType: {
                type: String,
                required: true
            },
            distanceDriven: {
                type: Array,
                required: true
            },
            distanceDrivenValue: {
                // type: Number,
                required: true
            },
            distanceDrivenType: {
                type: String,
                required: true
            },
            fuelConsumption: {
                type: Array,
                required: true
            },
            fuelConsumptionValue: {
                // type: Number,
                required: true
            },
            fuelConsumptionType: {
                type: String,
                required: true
            },
            startDate: {
                type: [String, Object],
                required: true
            },
            endDate: {
                type: [String, Object],
                required: true
            }
        },
        components: {
            ComChartLine
        },
        data() {
            return {
                hours: {
                    color: {
                        itemColor: "#3B4BD1",
                        lineColor: "#909090"
                    },
                    // total: 89989,
                    // data: [{ name: '1001', value: 273 }]
                },
                distance: {
                    color: {
                        itemColor: "#3B4BD1",
                        lineColor: "#909090"
                    },
                    // total: 89989,
                    // data: [{ name: '1001', value: 273 },]
                },
                fuel: {
                    color: {
                        itemColor: "#3B4BD1",
                        lineColor: "#909090"
                    },
                    // total: 34534,
                    // data: [{ name: '1031', value: 273 }]
                },
            }
        },
        computed: {
            unitKilometers() {
                return this.$unit.getKilometersUnit(this);
            },
            unitLiters() {
                return this.$unit.getLitersUnit(this);
            },
            unitKilometersAbbr() {
                let value = parseFloat(this.distanceDrivenValue);
                if (value !== 1) {
                    return this.$unit.getKilometersUnit(this);
                }
                return this.$unit.getKilometerUnit(this);
            },
            unitLitersAbbr() {
                let value = parseFloat(this.fuelConsumptionValue);
                if (value !== 1) {
                    return this.$unit.getLitersUnit(this);
                }
                return this.$unit.getLiterUnit(this);
            },
            unitHoursAbbr() {
                let value = parseFloat(this.hoursDrivenValue);
                return this.$tc('units.hours', value);
            },
            hoursUnitChoice() {
                return {
                    singular: this.$tc('units.hours', 1),
                    plural: this.$tc('units.hours', 2)
                }
            },
            kilometersUnitChoice() {
                return {
                    singular: this.$unit.getKilometerUnit(this),
                    plural: this.$unit.getKilometersUnit(this)
                };
            },
            litersUnitChoice() {
                return {
                    singular: this.$unit.getLiterUnit(this),
                    plural: this.$unit.getLitersUnit(this)
                };
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .dashboard-com-total-chart
        height 100%
        background-color $color_white

        .com-total-chart-main
            height 100%

            .com-total-chart-top, .com-total-chart-middle, .com-total-chart-bottom
                width 100%
                height 30%

                .ry-font
                    font-size: 22px;
                    position relative;
                    top -2px;
                    color: $color_black_first;

    .display-flex
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: column
        justify-content: space-between;
</style>