<template>
    <div class="dashboard-com-fleet-info-list ">
        <div class="com-fleet-info-list-table com-fleet-info-list-scroll">
            <div class="com-fleet-info-list-row" v-for="(item,index) in data" :key="index"
                 :title="item.name + ' - ' + formatValue(item.value)">
                <div class="com-fleet-info-list-left">
                    <span class="com-fleet-info-list-block via-vehicles" :style="{ color: item.color }"/>
                    {{ item.name }}
                </div>
                <div class="com-fleet-info-list-right">{{ formatValue(item.value) }}</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ComFleetInfoList",
        props: {
            data: {
                required: true
            },
            listPoint: {
                type: Number,
                default: -1
            }
        },
        data() {
            return {}
        },
        methods: {
            formatValue(value) {
                if (this.listPoint === -1 || isNaN(value)) return value;
                let numTmp = Math.pow(10, this.listPoint);
                value = Math.round(value * numTmp) / numTmp;
                return value.toFixed(this.listPoint);
            }
        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-content = $color_black_first
    $font-size = 12px
    $block-width = $font-size - 2px
    .dashboard-com-fleet-info-list
        display block
        height 100%
        margin-top 10px

        .com-fleet-info-list-table
            width 85%
            height 100%
            float right
            overflow-x hidden

            .com-fleet-info-list-row
                width 100%
                height 20px
                line-height 20px

                .com-fleet-info-list-left, .com-fleet-info-list-right
                    color $font-color-content
                    font-size $font-size
                    display inline-block
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap
                    cursor default

                .com-fleet-info-list-left
                    width 65%

                    .com-fleet-info-list-block
                        font-size 18px
                        display inline-block
                        width 20px
                        height 20px
                        margin-right 8px

                .com-fleet-info-list-right
                    width 35%

            .com-fleet-info-list-row:hover
                background-color $color_blue_hover

        .com-fleet-info-list-scroll::-webkit-scrollbar
            width: 10px;

        .com-fleet-info-list-scroll::-webkit-scrollbar-track
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;

        .com-fleet-info-list-scroll::-webkit-scrollbar-thumb
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);


    @media only screen and (max-width: 1600px) {
        .dashboard-com-fleet-info-list .com-fleet-info-list-table {
            width 100% !important
        }
    }

</style>
