<template>
    <div class="dashboard-com-fleet-list">
        <div class="com-fleet-list-left">
            <span class="com-fleet-list-list-box" :title="item.name" v-for="item in this.fleetList">
                <span class="com-fleet-list-tips-block via-vehicles" :style="{ color: item.color }"/>{{ item.name }}
            </span>
        </div>
<!--        <export-excel-button type="table" :data="excelData" file-name="dashboard-data">-->
<!--            <el-button class="com-fleet-list-btn" size="mini" type="primary">-->
<!--                <span class="via-download"/> Export-->
<!--            </el-button>-->
<!--        </export-excel-button>-->
        <div style="clear: both;"></div>
    </div>
</template>

<script>
    // import ExportExcelButton from '../common/ExportExcelButton';

    export default {
        name: "ComFleetList",
        props: {
            fleetList: {
                type: Array,
                required: true
            },
            excelData: {
                type: Array,
                required: true
            }
        },
        components: {
            // ExportExcelButton,
        },
        data() {
            return {}
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .dashboard-com-fleet-list
        background-color $color_white
        padding 5px 15px
        text-align right
        position relative

        > .com-fleet-list-left
            float left
            text-align left
            width 100%
            /** width calc(100% - 120px) 控制导出Excel文件样式使用 **/

            .com-fleet-list-list-box
                display inline-block
                line-height 22px
                cursor default
                text-overflow ellipsis
                white-space nowrap
                overflow hidden
                width 14%

                .com-fleet-list-tips-block
                    font-size 24px
                    display inline-block
                    margin-right 6px
                    position relative
                    top 4px

        .com-fleet-list-btn
            background-color $color_blue_second
            color white
            .icon
                color red;

        .com-fleet-list-btn:hover
            background-color $color_blue_second
            color $color_white
            opacity 0.9
</style>
