<template>
    <div>
        <div v-if="!isCollisionType" class="dashboard-com-top-title" >
            <div class="com-top-title-img-box">
                <slot></slot>
            </div>
            <div class="com-top-title-title-box com-top-title-show-title" v-show="true">
                <div class="com-top-title-title">{{ title }}</div>
                <div class="com-top-title-unit" :style="hideUnitStyle">{{ currentUnit }}</div>
            </div>
            <div class="com-top-title-title-box com-top-title-show-title-2" v-show="true">
                <div class="com-top-title-title">{{ unit }}</div>
            </div>
        </div>
        <div v-else class="dashboard-com-top-title" :class="[selected ? '' : 'hover-color']" style="cursor: pointer;height: 25px">
            <div class="com-top-title-img-box-collision" >
                <slot></slot>
            </div>
            <div class="com-top-title-title-box-collision com-top-title-show-title" >
                <div :class="[selected ? 'com-top-title-title' : '']">{{ title }}</div>
            </div>
            <div class="com-top-title-title-box-collision com-top-title-show-title-2" >
                <div :class="[selected ? 'com-top-title-title' : '']">{{ unit }}</div>
            </div>
            <div :class="[selected?'bottom-tab-selected':'bottom-tab']"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ComTopTitle",
        props: {
            title: {
                required: true,
                type: String
            },
            unit: {
                required: true,
                type: String
            },
            isCollisionType:{
                type:Boolean,
                default:false
            },
            selected:{
                type:Boolean,
                default: false
            }
        },
        data() {
            return {
                show: true,
                timer: null
            }
        },
        computed: {
            currentUnit() {
                if ((!!this.unit === false) || !!this.unit.trim() === false) {
                    return '.';
                }
                return '(' + this.unit + ')';
            },
            hideUnitStyle() {
                if ((!!this.unit === false) || !!this.unit.trim() === false) {
                    return {
                        color: 'rgba(255, 255, 255, 0)'
                    };
                }
                return {};
            }
        },
        methods:{

        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-title = $color_black_first
    $font-color-content = $color_black_first
    $font-color-value = $color_black_first
    $bottom-tab-selected-color = $color_blue_second

    @media only screen and (max-width: 1600px) {
        .com-top-title-show-title {
            display none !important
        }

        .dashboard-com-top-title .com-top-title-img-box .ry-font {
            top auto !important
        }

        .com-top-title-show-title-2 {
            display inline-block !important
            position relative
            top -3px
        }
    }

    .dashboard-com-top-title
        display block

        .com-top-title-img-box
            display inline-block
            height 30px
            line-height 30px
            margin-right 8px

            .ry-font
                font-size: 22px;
                color: $font-color-title;
                position: relative;
                top: -5px;

        .com-top-title-title-box
            display inline-block
            height 30px
            cursor default

            .com-top-title-title
                color $font-color-title
                line-height 14px


            .com-top-title-unit
                color $font-color-content !important
                font-size 12px
                line-height 16px

        .com-top-title-img-box-collision
            display inline-block
            height 25px
            margin-right 5px

            .com-top-title-title
                color $font-color-title


            .com-top-title-unit
                color $font-color-content !important
                font-size 12px

        .com-top-title-title-box-collision
            display inline-block
            height 25px

            .com-top-title-title
                color $font-color-title
                line-height 14px

            .com-top-title-unit
                color $font-color-content
                font-size 12px

        .com-top-title-show-title-2
            display none;

    .active-color
        color #8c939d

    .bottom-tab
        margin-top -3px
        background-color white
        height 4px
        cursor pointer

    .bottom-tab-selected
        margin-top -3px
        background-color $bottom-tab-selected-color
        height 4px
        cursor pointer

    .hover-color
        color #8c939d
    .hover-color:hover
        color $color_blue_second
</style>
