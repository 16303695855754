<template>
    <div>
        <div class="dashboard-page-main-v4">
            <div class="dashboard-page-main-v4-box">
                <div class="dashboard-top">
                    <ComFleetList class="border-radius" :fleet-list="fleet.data" :excel-data="excelData"/>
                </div>
                <div class="dashboard-middle">
                    <div class="dashboard-flex-box">
                        <com-hours-driven type="total" class="top-chart border-radius"
                                          :avg="hoursDrivenData.avg.data"
                                          :avg-value="hoursDrivenData.avg.value"
                                          :avg-value-num="hoursDrivenData.avg.valueNum"
                                          :total="hoursDrivenData.total.data"
                                          :total-value="hoursDrivenData.total.value"
                                          :total-value-num="hoursDrivenData.total.valueNum"/>
                    </div>
                    <div class="dashboard-flex-box">
                        <com-distance-driven type="total" class="top-chart border-radius"
                                             :avg="distanceDriverDataFormat.avg.data"
                                             :avg-value="distanceDriverDataFormat.avg.value"
                                             :avg-value-num="distanceDriverDataFormat.avg.valueNum"
                                             :total="distanceDriverDataFormat.total.data"
                                             :total-value="distanceDriverDataFormat.total.value"
                                             :total-value-num="distanceDriverDataFormat.total.valueNum"/>
                    </div>
                    <div class="dashboard-flex-box">
                        <com-fuel-consumption type="total" class="top-chart border-radius"
                                              :avg="fuelConsumptionDataFormat.avg.data"
                                              :avg-value="fuelConsumptionDataFormat.avg.value"
                                              :avg-value-num="fuelConsumptionDataFormat.avg.valueNum"
                                              :total="fuelConsumptionDataFormat.total.data"
                                              :total-value="fuelConsumptionDataFormat.total.value"
                                              :total-value-num="fuelConsumptionDataFormat.total.valueNum"/>
                    </div>
                    <div class="dashboard-flex-box">
                        <com-collision-alerts class="top-chart border-radius"
                                              @alertModel="changeAlertModel"
                                              :collisionType="collisionType"
                                              :total="collisionModel==='collisionType'?collisionAlertsData.total.data:parkingAlertsData.total.data"
                                              :total-value-num="collisionModel==='collisionType'?collisionAlertsData.total.value:parkingAlertsData.total.value"
                                              :total-value="formatNumToStringForAlerts(collisionModel==='collisionType'?collisionAlertsData.total.value:parkingAlertsData.total.value, 1)"/>
                    </div>
                    <div class="dashboard-flex-box">
                        <com-safety-score class="top-chart border-radius"
                                          :avg="safetyScoreData.avg.data"
                                          :avg-value="safetyScoreData.avg.value"
                                          :avg-value-num="safetyScoreData.avg.valueNum"/>
                    </div>
                </div>
                <div class="dashboard-bottom">
                    <div class="dashboard-left">
                        <com-total-chart class="dashboard-total-chart border-radius"
                                         :start-date="date.startDate"
                                         :end-date="date.endDate"
                                         :hours-driven="totalHoursDrivenData.data"
                                         :hours-driven-value="totalHoursDrivenData.value"
                                         :hours-driven-type="totalHoursDrivenData.type"
                                         :distance-driven="totalDistanceDrivenDataFormat.data"
                                         :distance-driven-value="totalDistanceDrivenDataFormat.value"
                                         :distance-driven-type="totalDistanceDrivenData.type"
                                         :fuel-consumption="totalFuelConsumptionDataFormat.data"
                                         :fuel-consumption-value="totalFuelConsumptionDataFormat.value"
                                         :fuel-consumption-type="totalFuelConsumptionData.type"/>
                    </div>
                    <div class="dashboard-right">
                        <div class="dashboard-top border-radius">
                            <com-ranking-by-vehicle class="dashboard-ranking-table border-radius"
                                                    :data="rankingByVehicleDataFormat.data"/>
                        </div>
                        <div class="dashboard-bottom border-radius">
                            <com-ranking-by-driver class="dashboard-ranking-table border-radius"
                                                   :data="rankingByDriverDataFormat.data"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bus from "../../utils/bus";
    import ComFleetList from '../../components/dashboard/ComFleetList';
    import ComHoursDriven from '../../components/dashboard/ComHoursDriven';
    import ComDistanceDriven from '../../components/dashboard/ComDistanceDriven';
    import ComFuelConsumption from '../../components/dashboard/ComFuelConsumption';
    import ComCollisionAlerts from '../../components/dashboard/ComCollisionAlerts';
    import ComSafetyScore from '../../components/dashboard/ComSafetyScore';
    import ComTotalChart from '../../components/dashboard/ComTotalChart';
    import ComRankingByVehicle from '../../components/dashboard/ComRankingByVehicle';
    import ComRankingByDriver from '../../components/dashboard/ComRankingByDriver';
    import Sort from "../../assets/js/Sort";

    export default {
        name: "MainV4",
        components: {
            ComFleetList,
            ComHoursDriven,
            ComDistanceDriven,
            ComFuelConsumption,
            ComCollisionAlerts,
            ComSafetyScore,
            ComTotalChart,
            ComRankingByVehicle,
            ComRankingByDriver,
        },
        beforeMount() {
            this.initDate();
        },
        mounted() {
            bus.$on('dashboardDateSelect', this.handleDate)
            this.$nextTick(() => {
                this.initApiData();
                document.getElementById('mainContent').style.height = '100%';
                document.getElementById('mainContent').style.backgroundColor = '#E2E2E2';
            });
            bus.$on('collapse', this.resize);
            window.addEventListener('resize', () => {
                bus.$emit('chartResize');
            })
        },
        beforeRouteLeave(to, from, next) {
            document.getElementById('mainContent').style.height = '';
            document.getElementById('mainContent').style.backgroundColor = "";
            next();
        },
        data() {
            return {
                collisionModel: "collisionType",
                collisionType:true,
                date: {
                    startDate: null,
                    endDate: null,
                    lastDay: 7, // 查询数据的天数
                    showDataByDayRange: []
                },
                apiData: {
                    fleet: null,
                },
                fleet: {
                    data: [],
                    colorListMap: new Map(),
                    vehicleIdColorMap: new Map(),
                    vehicleIdFleetNameMap: new Map(),
                },
                hoursDrivenData: {
                    total: {data: [], value: 0, valueNum: 0},
                    avg: {data: [], value: 0, valueNum: 0},
                    line: [],
                    totalMap: new Map(),
                    avgMap: new Map(),
                    lineMap: new Map()
                },
                distanceDriverData: {
                    total: {data: [], value: 0, valueNum: 0},
                    avg: {data: [], value: 0, valueNum: 0},
                    line: [],
                    totalMap: new Map(),
                    avgMap: new Map(),
                    lineMap: new Map()
                },
                fuelConsumptionData: {
                    total: {data: [], value: 0, valueNum: 0},
                    avg: {data: [], value: 0, valueNum: 0},
                    line: [],
                    totalMap: new Map(),
                    avgMap: new Map(),
                    lineMap: new Map()
                },
                collisionAlertsData: {
                    total: {data: [], value: 0},
                    avg: {data: [], value: 0},
                    line: [],
                    totalMap: new Map(),
                    avgMap: new Map(),
                    lineMap: new Map()
                },
                parkingAlertsData:{
                    total: {data: [], value: 0},
                    avg: {data: [], value: 0},
                    line: [],
                    totalMap: new Map(),
                    avgMap: new Map(),
                    lineMap: new Map(),
                    parkingAlertMap: new Map()
                },
                safetyScoreData: {
                    total: {data: [], value: 0, valueNum: 0},
                    avg: {data: [], value: 0, valueNum: 0},
                    line: [],
                    totalMap: new Map(),
                    avgMap: new Map(),
                    lineMap: new Map()
                },
                rankingByVehicleData: {
                    data: [],
                    map: new Map()
                },
                rankingByDriverData: {
                    data: [],
                    map: new Map()
                },
                totalHoursDrivenData: {
                    data: [],
                    value: 0,
                    type: 'day',
                    map: new Map()
                },
                totalDistanceDrivenData: {
                    data: [],
                    value: 0,
                    type: 'day',
                    map: new Map()
                },
                totalFuelConsumptionData: {
                    data: [],
                    value: 0,
                    type: 'day',
                    map: new Map()
                },
            }
        },
        computed: {
            unitKm() {
                return this.$unit.getKmUnit(this);
            },
            unitL() {
                return this.$unit.getLUnit(this);
            },
            distanceDriverDataFormat() {
                let totalValue = this.distanceDriverData.total.value;
                let avgValue = this.distanceDriverData.avg.value;
                totalValue = this.$unit.convertKilometerUnit(totalValue);
                avgValue = this.$unit.convertKilometerUnit(avgValue);
                let avgData = [];
                this.distanceDriverData.avg.data.forEach(item => {
                    avgData.push({
                        name: item.name,
                        value: this.$unit.convertKilometerUnit(item.value),
                        color: item.color
                    });
                });
                let totalData = [];
                this.distanceDriverData.total.data.forEach(item => {
                    totalData.push({
                        name: item.name,
                        value: this.$unit.convertKilometerUnit(item.value),
                        color: item.color
                    })
                });
                return {
                    total: {
                        data: totalData,
                        value: this.formatNumToString(totalValue, 1),
                        valueNum: this.numRound(totalValue, 1)
                    },
                    avg: {
                        data: avgData,
                        value: this.formatNumToString(avgValue, 1),
                        valueNum: this.numRound(avgValue, 1)
                    }
                };
            },
            fuelConsumptionDataFormat() {
                let totalValue = this.fuelConsumptionData.total.value;
                let avgValue = this.fuelConsumptionData.avg.value;
                totalValue = this.$unit.convertLiterUnit(totalValue);
                avgValue = this.$unit.convertKmLUnit(avgValue);
                let avgData = [];
                this.fuelConsumptionData.avg.data.forEach(item => {
                    avgData.push({
                        name: item.name,
                        value: this.$unit.convertKmLUnit(item.value),
                        color: item.color
                    });
                });
                let totalData = [];
                this.fuelConsumptionData.total.data.forEach(item => {
                    totalData.push({
                        name: item.name,
                        value: this.$unit.convertLiterUnit(item.value),
                        color: item.color
                    })
                });
                return {
                    total: {
                        data: this.sortDataList(totalData),
                        value: this.formatNumToString(totalValue, 1),
                        valueNum: this.numRound(totalValue, 1)
                    },
                    avg: {
                        data: this.sortDataList(avgData),
                        value: this.formatNumToString(avgValue, 1),
                        valueNum: this.numRound(avgValue, 1)
                    }
                };
            },
            totalDistanceDrivenDataFormat() {
                let value = this.$unit.convertKilometerUnit(this.totalDistanceDrivenData.value);
                let data = [];
                this.totalDistanceDrivenData.data.forEach(item => {
                    let valueTmp = this.$unit.convertKilometerUnit(item.value);
                    data.push({
                        name: item.name,
                        value: this.numRound(valueTmp, 1).toFixed(1)
                    });
                });
                return {
                    data: data,
                    value: this.numRound(value, 1).toFixed(1)
                };
            },
            totalFuelConsumptionDataFormat() {
                let value = this.$unit.convertLiterUnit(this.totalFuelConsumptionData.value);
                let data = [];
                this.totalFuelConsumptionData.data.forEach(item => {
                    let valueTmp = this.$unit.convertLiterUnit(item.value);
                    data.push({
                        name: item.name,
                        value: this.numRound(valueTmp, 1).toFixed(1)
                    });
                });
                return {
                    data: data,
                    value: this.numRound(value, 1).toFixed(1)
                };
            },
            rankingByVehicleDataFormat() {
                let data = [];
                this.rankingByVehicleData.data.forEach(item => {
                    let tmpItem = Object.assign({}, item);
                    tmpItem.distance = this.$unit.convertKilometerUnit(tmpItem.distance);
                    tmpItem.fuel = this.$unit.convertLiterUnit(tmpItem.fuel);
                    data.push(tmpItem);
                });
                return {data: data};
            },
            rankingByDriverDataFormat() {
                let data = [];
                this.rankingByDriverData.data.forEach(item => {
                    let tmpItem = Object.assign({}, item);
                    tmpItem.distance = this.$unit.convertKilometerUnit(tmpItem.distance);
                    tmpItem.fuel = this.$unit.convertLiterUnit(tmpItem.fuel);
                    data.push(tmpItem);
                });
                return {data: data};
            },
            excelData: function () {
                let deviceTh = [
                    {name: 'NO.', style: 'width: 60px;'},
                    {name: 'Fleet', style: 'width: 120px;'},
                    {name: 'Vehicle Name', style: 'width: 120px;'},
                    {name: 'Plate Number', style: 'width: 120px;'},
                    {name: 'Hours Driven (Hrs)', style: 'width: 120px;'},
                    {name: 'Distance Driven (' + this.unitKm + ')', style: 'width: 150px;'},
                    {name: "Fuel Consumption (" + this.unitL + ")", style: 'width: 150px;'},
                    {name: 'Collision Alerts', style: 'width: 100px;'},
                    {name: 'Driver Score', style: 'width: 100px;'}
                ];
                let deviceNo = 0, deviceTds = [];
                this.rankingByVehicleDataFormat.data.forEach(item => {
                    deviceNo++;
                    let data = [
                        {value: deviceNo, style: ''},
                        {value: item.fleetName || '', style: ''},
                        {value: item.name || '', style: ''},
                        {value: item.plateNumber, style: ''},
                        {value: this.numRound(item.time, 1).toFixed(1), style: ''},
                        {value: this.numRound(item.distance, 1).toFixed(1), style: ''},
                        {value: this.numRound(item.fuel, 1).toFixed(1), style: ''},
                        {value: item.alert, style: ''},
                        {value: this.numRound(item.score, 1).toFixed(1), style: ''}
                    ];
                    deviceTds.push(data);
                });
                let driverTh = [
                    {name: 'NO.', style: 'width: 60px;'},
                    {name: 'Driver', style: 'width: 120px;'},
                    {name: 'Hours Driven (Hrs)', style: 'width: 120px;'},
                    {name: 'Distance Driven (' + this.unitKm + ')', style: 'width: 150px;'},
                    {name: "Fuel Consumption (" + this.unitL + ")", style: 'width: 150px;'},
                    {name: 'Collision Alerts', style: 'width: 120px;'},
                    {name: 'Driver Score', style: 'width: 120px;'}
                ];
                let driverNo = 0, driverTds = [];
                this.rankingByDriverDataFormat.data.forEach(item => {
                    driverNo++;
                    let data = [
                        {value: driverNo, style: ''},
                        {value: item.name || '', style: ''},
                        {value: this.numRound(item.time, 1).toFixed(1), style: ''},
                        {value: this.numRound(item.distance, 1).toFixed(1), style: ''},
                        {value: this.numRound(item.fuel, 1).toFixed(1), style: ''},
                        {value: item.alert, style: ''},
                        {value: this.numRound(item.score, 1).toFixed(1), style: ''}
                    ];
                    driverTds.push(data);
                });
                return [
                    {sheetName: 'Dashboard-Device', style: 'font-family: Calibri;', th: deviceTh, td: deviceTds},
                    {sheetName: 'Dashboard-Driver', style: 'font-family: Calibri;', th: driverTh, td: driverTds}
                ];
                // 数组格式
                // let deviceData = [
                //     [ "NO.", "Fleet", "Vehicle Name", "Plate Number", "Hours Driven(H)", "Distance Driven(KM)", "Fuel Consumption("+ this.unit +")", "Collision Alerts", "Driver Score" ]
                // ];
                // let deviceNo = 0;
                // this.rankingByVehicleData.data.forEach(item => {
                //     deviceNo++;
                //     let data = [
                //         deviceNo, item.fleetName, item.name, item.plateNumber, item.time,
                //         item.distance, item.fuel, item.alert, item.score
                //     ];
                //     deviceData.push(data);
                // });
                // let driverData = [
                //     [ "NO.", "Driver", "Hours Driven(H)", "Distance Driven(KM)", "Fuel Consumption("+ this.unit +")", "Collision Alerts", "Driver Score" ]
                // ];
                // let driverNo = 0;
                // this.rankingByDriverData.data.forEach(item => {
                //     driverNo++;
                //     let data = [
                //         driverNo, item.name, item.time,
                //         item.distance, item.fuel, item.alert, item.score
                //     ];
                //     driverData.push(data);
                // });
                // return [
                //     { sheetName: 'Dashboard-Device', colWidth: [8,15,20,15,15,20,20,16,12], data: deviceData },
                //     { sheetName: 'Dashboard-Driver', colWidth: [8,20,15,20,20,16,12], data: driverData }
                // ];
            }
        },
        methods: {
            changeAlertModel(model){
                console.log(`current model = ${model}`)
                this.collisionModel = model
            },
            resize() {
                setTimeout(function () {
                    bus.$emit('chartResize');
                }, 260);
            },
            handleDate(startDate, endDate) {
                this.date.startDate = startDate;
                this.date.endDate = endDate;
                this.changeLastDays(startDate, endDate);
                this.initAllChartDataToZero();
                this.initApiData();
            },
            initApiData() {
                if (this.fleet.data.length === 0) {
                    this.getFleetDataFromApi();
                    return;
                }
                this.getAllDateFromApi();
            },
            getFleetDataFromApi() {
                let defaultColorsList = [
                    '#74aafd', '#4e66cf', '#ffe115', '#6aac21', '#b74983',
                    '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'
                ];
                let defaultColorIndex = 0;
                this.$serverApi.fleet.fleetList(data => {
                    this.fleet.data = [];
                    this.fleet.colorListMap.clear();
                    data.Sub.sort((itemA, itemB) => {
                        return Sort.NameSort(itemA.Name, itemB.Name);
                    });
                    data.Sub.forEach(item => {
                        let color = item.Color;
                        if (!color) {
                            color = defaultColorsList[defaultColorIndex];
                            defaultColorIndex++;
                            if (defaultColorIndex >= defaultColorsList.length) {
                                defaultColorIndex = 0;
                            }
                        }
                        if (item.Vehicles) {
                            item.Vehicles.forEach(vehicle => {
                                this.fleet.vehicleIdColorMap.set(vehicle.Id, color);
                                this.fleet.vehicleIdFleetNameMap.set(vehicle.Id, item.Name);
                            });
                        }
                        this.fleet.colorListMap.set(item.Id, color);
                        this.fleet.data.push({
                            name: item.Name,
                            color: color
                        });
                    });
                    if (this.fleet.data.length === 0) return;
                    this.getAllDateFromApi();
                })
            },
            getAllDateFromApi() {
                this.getHoursDrivenDataFromApi();
                this.getDistanceDrivenDataFromApi();
                this.getFuelConsumptionDataFromApi();
                this.getCollisionAlertsDataFromApi();
                this.getParkingAlertsDataFromApi();
                this.getSafetyScoreDataFromApi();
                this.getRankingByVehicleDataFromApi();
                this.getRankingByDriverDataFromApi();
            },
            getHoursDrivenDataFromApi() {
                let avgMapKey = 'avg-' + this.date.startDate + '-' + this.date.endDate;
                let totalMapKey = 'total-' + this.date.startDate + '-' + this.date.endDate;
                let totalLineMapKey = this.getTotalMapLineKey(this.date.startDate, this.date.endDate);
                let avgHistoryData = this.hoursDrivenData.avgMap.get(avgMapKey);
                let totalHistoryData = this.hoursDrivenData.totalMap.get(totalMapKey);
                let totalLineData = this.totalHoursDrivenData.map.get(totalLineMapKey);
                if (avgHistoryData !== undefined && totalHistoryData !== undefined && totalLineData !== undefined) {
                    this.hoursDrivenData.avg = {
                        data: avgHistoryData.data,
                        value: this.formatNumToString(avgHistoryData.value, 1),
                        valueNum: this.numRound(avgHistoryData.value, 1),
                    };
                    this.hoursDrivenData.total = {
                        data: totalHistoryData.data,
                        value: this.formatNumToString(totalHistoryData.value, 1),
                        valueNum: this.numRound(totalHistoryData.value, 1),
                    };
                    this.totalHoursDrivenData.data = totalLineData.data;
                    this.totalHoursDrivenData.value = totalLineData.value;
                    this.totalHoursDrivenData.type = totalLineData.type;
                    return;
                }
                this.$serverApi.dashboard.fleetHours({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    let decimalPoint = {avg: 2, avgValue: 2, total: 2, totalValue: 2};
                    let formatData = this.formatApiData2Total(data.Fleets, this.date.lastDay, 'Hours', decimalPoint);
                    this.hoursDrivenData.avg.data = formatData.avg.data;
                    this.hoursDrivenData.avg.value = this.formatNumToString(formatData.avg.value, 1);
                    this.hoursDrivenData.avg.valueNum = this.numRound(formatData.avg.value, 1);
                    this.hoursDrivenData.avgMap.set(avgMapKey, Object.assign({}, {
                        data: formatData.avg.data, value: formatData.avg.value
                    }));
                    this.hoursDrivenData.total.data = formatData.total.data;
                    this.hoursDrivenData.total.value = this.formatNumToString(formatData.total.value, 1);
                    this.hoursDrivenData.total.valueNum = this.numRound(formatData.total.value, 1);
                    this.hoursDrivenData.totalMap.set(totalMapKey, Object.assign({}, {
                        data: formatData.total.data, value: formatData.total.value
                    }));
                    this.getTotalHoursDrivenData(data.Fleets);
                })
            },
            getDistanceDrivenDataFromApi() {
                let avgMapKey = 'avg-' + this.date.startDate + '-' + this.date.endDate;
                let totalMapKey = 'total-' + this.date.startDate + '-' + this.date.endDate;
                let totalLineMapKey = this.getTotalMapLineKey(this.date.startDate, this.date.endDate);
                let avgHistoryData = this.distanceDriverData.avgMap.get(avgMapKey);
                let totalHistoryData = this.distanceDriverData.totalMap.get(totalMapKey);
                let totalLineData = this.totalDistanceDrivenData.map.get(totalLineMapKey);
                if (avgHistoryData !== undefined && totalHistoryData !== undefined && totalLineData !== undefined) {
                    this.distanceDriverData.avg = {
                        data: avgHistoryData.data,
                        value: avgHistoryData.value,
                        valueNum: avgHistoryData.value,
                    };
                    this.distanceDriverData.total = {
                        data: totalHistoryData.data,
                        value: totalHistoryData.value,
                        valueNum: totalHistoryData.value,
                    };
                    this.totalDistanceDrivenData.data = totalLineData.data;
                    this.totalDistanceDrivenData.value = totalLineData.value;
                    this.totalDistanceDrivenData.type = totalLineData.type;
                    return;
                }
                this.$serverApi.dashboard.fleetDistance({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    let decimalPoint = {avg: 2, avgValue: 2, total: 2, totalValue: 2};
                    let formatData = this.formatApiData2Total(data.Fleets, this.date.lastDay, 'Distance', decimalPoint);
                    this.distanceDriverData.avg.data = formatData.avg.data;
                    this.distanceDriverData.avg.value = formatData.avg.value;
                    this.distanceDriverData.avg.valueNum = formatData.avg.value;
                    this.distanceDriverData.avgMap.set(avgMapKey, Object.assign({}, {
                        data: formatData.avg.data, value: formatData.avg.value
                    }));
                    this.distanceDriverData.total.data = formatData.total.data;
                    this.distanceDriverData.total.value = formatData.total.value;
                    this.distanceDriverData.total.valueNum = formatData.total.value;
                    this.distanceDriverData.totalMap.set(totalMapKey, Object.assign({}, {
                        data: formatData.total.data, value: formatData.total.value
                    }));
                    this.getTotalDistanceDrivenData(data.Fleets);
                })
            },
            getFuelConsumptionDataFromApi() {
                let avgMapKey = 'avg-' + this.date.startDate + '-' + this.date.endDate;
                let totalMapKey = 'total-' + this.date.startDate + '-' + this.date.endDate;
                let totalLineMapKey = this.getTotalMapLineKey(this.date.startDate, this.date.endDate);
                let avgHistoryData = this.fuelConsumptionData.avgMap.get(avgMapKey);
                let totalHistoryData = this.fuelConsumptionData.totalMap.get(totalMapKey);
                let totalLineData = this.totalFuelConsumptionData.map.get(totalLineMapKey);
                if (avgHistoryData !== undefined && totalHistoryData !== undefined && totalLineData !== undefined) {
                    this.fuelConsumptionData.avg = {
                        data: avgHistoryData.data,
                        value: avgHistoryData.value,
                        valueNum: avgHistoryData.value,
                    };
                    this.fuelConsumptionData.total = {
                        data: totalHistoryData.data,
                        value: totalHistoryData.value,
                        valueNum: totalHistoryData.value,
                    };
                    this.totalFuelConsumptionData.data = totalLineData.data;
                    this.totalFuelConsumptionData.value = totalLineData.value;
                    this.totalFuelConsumptionData.type = totalLineData.type;
                    return;
                }
                this.$serverApi.dashboard.fleetFuelConsumption({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    let decimalPoint = {avg: 2, avgValue: 2, total: 2, totalValue: 2};
                    // let formatData = this.formatApiData2Total(data.Fleets, this.date.lastDay, 'TotalFuelConsumed', decimalPoint);
                    let formatData = this.formatApiFleetFuelConsumptionData(data.Fleets, decimalPoint);
                    this.fuelConsumptionData.avg.data = formatData.avg.data;
                    this.fuelConsumptionData.avg.value = formatData.avg.value;
                    this.fuelConsumptionData.avg.valueNum = formatData.avg.value;
                    this.fuelConsumptionData.avgMap.set(avgMapKey, Object.assign({}, {
                        data: formatData.avg.data, value: formatData.avg.value
                    }));
                    this.fuelConsumptionData.total.data = formatData.total.data;
                    this.fuelConsumptionData.total.value = formatData.total.value;
                    this.fuelConsumptionData.total.valueNum = formatData.total.value;
                    this.fuelConsumptionData.totalMap.set(totalMapKey, Object.assign({}, {
                        data: formatData.total.data, value: formatData.total.value
                    }));
                    this.getTotalFuelConsumptionData(data.Fleets);
                })
            },
            getCollisionAlertsDataFromApi() {
                let avgMapKey = 'avg-' + this.date.startDate + '-' + this.date.endDate;
                let totalMapKey = 'total-' + this.date.startDate + '-' + this.date.endDate;
                let avgHistoryData = this.collisionAlertsData.avgMap.get(avgMapKey);
                let totalHistoryData = this.collisionAlertsData.totalMap.get(totalMapKey);
                if (avgHistoryData !== undefined && totalHistoryData !== undefined) {
                    this.collisionAlertsData.avg = {
                        data: avgHistoryData.data, value: avgHistoryData.value
                    };
                    this.collisionAlertsData.total = {
                        data: totalHistoryData.data,
                        value: totalHistoryData.value,
                    };
                    return;
                }
                this.$serverApi.dashboard.fleetAlerts({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    let decimalPoint = {avg: 0, avgValue: 0, total: 0, totalValue: 0};
                    let formatData = this.formatApiData2Total(data.Fleets, this.date.lastDay, 'CollisionCount', decimalPoint);
                    this.collisionAlertsData.avg.data = formatData.avg.data;
                    this.collisionAlertsData.avg.value = formatData.avg.value;
                    this.collisionAlertsData.avgMap.set(avgMapKey, Object.assign({}, {
                        data: formatData.avg.data, value: formatData.avg.value
                    }));
                    this.collisionAlertsData.total.data = formatData.total.data;
                    this.collisionAlertsData.total.value = formatData.total.value;
                    this.collisionAlertsData.totalMap.set(totalMapKey, Object.assign({}, {
                        data: formatData.total.data, value: formatData.total.value
                    }));
                })
            },
            getParkingAlertsDataFromApi(){
                let totalMapKey = 'total-' + this.date.startDate + '-' + this.date.endDate;
                let parkingAlertData = this.parkingAlertsData.parkingAlertMap.get(totalMapKey);
                if (parkingAlertData !== undefined) {
                    this.parkingAlertsData.total = {
                        data: parkingAlertData.data,
                        value: parkingAlertData.value,
                    };
                    return;
                }
                this.$serverApi.dashboard.parkingAlerts({
                    StartDate:this.date.startDate,
                    EndDate: this.date.endDate
                },data =>{
                    let count = 0;
                    let tempData = [];
                    data.Fleets.forEach(item =>{
                        let color = this.fleet.colorListMap.get(item.FleetId);
                        tempData.push({
                            "name":item.FleetName,
                            "value":item.ParkingCollisionCount,
                            "color":color
                        });
                        count += item.ParkingCollisionCount;
                    });
                    this.parkingAlertsData.total.data = this.sortDataList(tempData);
                    this.parkingAlertsData.total.value = count;
                    this.parkingAlertsData.totalMap.set(totalMapKey, Object.assign({}, {
                        data: this.parkingAlertsData.total.data, value: this.parkingAlertsData.total.value
                    }));
                })

            },
            getSafetyScoreDataFromApi() {
                let avgMapKey = 'avg-' + this.date.startDate + '-' + this.date.endDate;
                let totalMapKey = 'total-' + this.date.startDate + '-' + this.date.endDate;
                let avgHistoryData = this.safetyScoreData.avgMap.get(avgMapKey);
                let totalHistoryData = this.safetyScoreData.totalMap.get(totalMapKey);
                if (avgHistoryData !== undefined && totalHistoryData !== undefined) {
                    this.safetyScoreData.avg = {
                        data: avgHistoryData.data,
                        valueNum: this.numRound(avgHistoryData.value, 1),
                        value: this.formatNumToString(avgHistoryData.value, 1),
                    };
                    this.safetyScoreData.total = {
                        data: totalHistoryData.data,
                        valueNum: this.numRound(totalHistoryData.value, 1),
                        value: this.formatNumToString(totalHistoryData.value, 1),
                    };
                    return;
                }
                this.$serverApi.dashboard.fleetDriverScore({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    let decimalPoint = {avg: 2, avgValue: 2, total: 2, totalValue: 2};
                    let formatData = this.formatApiData2Total(data.Fleets, this.date.lastDay, 'DriverScore', decimalPoint);
                    this.safetyScoreData.avg.data = formatData.avg.data;
                    this.safetyScoreData.avg.valueNum = this.numRound(formatData.avg.value, 1);
                    this.safetyScoreData.avg.value = this.formatNumToString(formatData.avg.value, 1);
                    this.safetyScoreData.avgMap.set(avgMapKey, Object.assign({}, {
                        data: formatData.avg.data, value: formatData.avg.value
                    }));
                    this.safetyScoreData.total.data = formatData.total.data;
                    this.safetyScoreData.total.valueNum = this.numRound(formatData.total.value, 1);
                    this.safetyScoreData.total.value = this.formatNumToString(formatData.total.value, 1);
                    this.safetyScoreData.totalMap.set(totalMapKey, Object.assign({}, {
                        data: formatData.total.data, value: formatData.total.value
                    }));
                })
            },
            getRankingByVehicleDataFromApi() {
                let mapKey = 'list-' + this.date.startDate + '-' + this.date.endDate;
                let mapHistoryData = this.rankingByVehicleData.map.get(mapKey);
                if (mapHistoryData !== undefined) {
                    this.rankingByVehicleData.data = mapHistoryData;
                    return;
                }
                this.$serverApi.dashboard.driverScoreByVehicle({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    this.rankingByVehicleData.data = data.Vehicles;
                    let newVehicles = [];
                    let mapNewVehicles = [];
                    data.Vehicles.forEach(vehicle => {
                        if (
                            vehicle.VehicleId !== undefined &&
                            vehicle.VehicleName !== undefined &&
                            vehicle.Hours !== undefined &&
                            vehicle.Distance !== undefined &&
                            vehicle.TotalFuelConsumed !== undefined &&
                            vehicle.CollisionCount !== undefined &&
                            vehicle.DriverScore !== undefined &&
                            vehicle.PlateNumber !== undefined
                        ) {
                            let collisionCount = vehicle.CollisionCount ? vehicle.CollisionCount : 0,
                                driverAlertCount = vehicle.DriverAlertCount ? vehicle.DriverAlertCount : 0,
                                parkingCollisionCount = vehicle.ParkingCollisionCount ? vehicle.ParkingCollisionCount : 0,
                                unpermittedCount = vehicle.UnpermittedDriverCount ? vehicle.UnpermittedDriverCount : 0;
                            let color = this.fleet.vehicleIdColorMap.get(vehicle.VehicleId);
                            let fleetName = this.fleet.vehicleIdFleetNameMap.get(vehicle.VehicleId);
                            color = color ? color : '';
                            let item = {
                                fleetName: fleetName || 'Unassigned Vehicles',
                                name: vehicle.VehicleName,
                                time: vehicle.Hours,
                                distance: vehicle.Distance,
                                fuel: vehicle.TotalFuelConsumed,
                                collisionCount: collisionCount,
                                driverAlertCount: driverAlertCount,
                                unpermittedCount: unpermittedCount,
                                parkingCollisionCount: parkingCollisionCount,
                                alert: collisionCount + driverAlertCount + parkingCollisionCount + unpermittedCount,
                                score: parseFloat(vehicle.DriverScore.toFixed(0)),
                                color: color,
                                plateNumber: vehicle.PlateNumber
                            };
                            newVehicles.push(item);
                            mapNewVehicles.push(Object.assign({}, item));
                        }
                    });
                    this.rankingByVehicleData.data = newVehicles;
                    this.rankingByVehicleData.map.set(mapKey, mapNewVehicles);
                });
            },
            getRankingByDriverDataFromApi() {
                let mapKey = 'list-' + this.date.startDate + '-' + this.date.endDate;
                let mapHistoryData = this.rankingByDriverData.map.get(mapKey);
                if (mapHistoryData !== undefined) {
                    this.rankingByDriverData.data = mapHistoryData;
                    return;
                }
                this.$serverApi.dashboard.driverScoreByDriver({
                    StartDate: this.date.startDate,
                    EndDate: this.date.endDate
                }, data => {
                    let newDriver = [];
                    let mapNewDriver = [];
                    data.Drivers.forEach((driver, index) => {
                        let name = driver.DriverId === '0'
                            ? 'Unknown'
                            : driver.DriverName;
                        let collisionCount = driver.CollisionCount ? driver.CollisionCount : 0,
                            driverAlertCount = driver.DriverAlertCount ? driver.DriverAlertCount : 0,
                            unpermittedCount = driver.UnpermittedDriverCount ? driver.UnpermittedDriverCount : 0;
                        let item = {
                            name: name,
                            time: driver.Hours,
                            distance: driver.Distance,
                            fuel: driver.TotalFuelConsumed,
                            collisionCount: collisionCount,
                            driverAlertCount: driverAlertCount,
                            unpermittedCount: unpermittedCount,
                            alert: collisionCount + driverAlertCount + unpermittedCount,
                            score: parseFloat(driver.DriverScore.toFixed(0)),
                            color: index % 2 === 1 ? 'rgb(0, 0, 0, .3)' : 'rgb(0, 0, 0, .6)',
                        };
                        if (driver.DriverId !== '0' && driver.DriverName) {
                            newDriver.push(item);
                            mapNewDriver.push(Object.assign({}, item));
                        }
                    });
                    this.rankingByDriverData.data = newDriver;
                    this.rankingByDriverData.map.set(mapKey, mapNewDriver);
                });
            },
            getTotalHoursDrivenData(items) {
                let totalLineMapKey = this.getTotalMapLineKey(this.date.startDate, this.date.endDate);
                let showType = this.showDateType(this.date.startDate, this.date.endDate);
                let result = {data: [], value: 0, type: 'day'};
                let dataType = 'day';
                if (showType === 'day') {
                    dataType = 'day';
                    result = this.initDataByDay(this.date.startDate, this.date.endDate, items, "Hours", 2);
                } else if (showType === 'week') {
                    dataType = 'week';
                    result = this.initDataByWeek(this.date.startDate, this.date.endDate, items, "Hours", 2);
                } else {
                    dataType = 'month';
                    result = this.initDataByMonth(this.date.startDate, this.date.endDate, items, "Hours", 2);
                }
                result.type = dataType;
                let formatResultValue = this.numRound(result.value, 1).toFixed(1);
                result.data.forEach(item => {
                    item.value = this.numRound(item.value, 1).toFixed(1);
                });
                this.totalHoursDrivenData.data = result.data;
                this.totalHoursDrivenData.value = formatResultValue;
                this.totalHoursDrivenData.type = result.type;
                this.totalHoursDrivenData.map.set(totalLineMapKey, Object.assign({}, {
                    data: result.data,
                    value: formatResultValue,
                    type: result.type
                }));
            },
            getTotalDistanceDrivenData(items) {
                let totalLineMapKey = this.getTotalMapLineKey(this.date.startDate, this.date.endDate);
                let showType = this.showDateType(this.date.startDate, this.date.endDate);
                let result = {data: [], value: 0, type: 'day'};
                let dataType = 'day';
                if (showType === 'day') {
                    dataType = 'day';
                    result = this.initDataByDay(this.date.startDate, this.date.endDate, items, 'Distance', 2);
                } else if (showType === 'week') {
                    dataType = 'week';
                    result = this.initDataByWeek(this.date.startDate, this.date.endDate, items, 'Distance', 2);
                } else {
                    dataType = 'month';
                    result = this.initDataByMonth(this.date.startDate, this.date.endDate, items, 'Distance', 2);
                }
                result.type = dataType;
                this.totalDistanceDrivenData.data = result.data;
                this.totalDistanceDrivenData.value = result.value;
                this.totalDistanceDrivenData.type = result.type;
                this.totalDistanceDrivenData.map.set(totalLineMapKey, Object.assign({}, {
                    data: result.data,
                    value: result.value,
                    type: result.type
                }));
            },
            getTotalFuelConsumptionData(items) {
                let totalLineMapKey = this.getTotalMapLineKey(this.date.startDate, this.date.endDate);
                let showType = this.showDateType(this.date.startDate, this.date.endDate);
                let result = {data: [], value: 0, type: 'day'};
                let dataType = 'day';
                if (showType === 'day') {
                    dataType = 'day';
                    result = this.initDataByDay(this.date.startDate, this.date.endDate, items, 'TotalFuelConsumed', 2);
                } else if (showType === 'week') {
                    dataType = 'week';
                    result = this.initDataByWeek(this.date.startDate, this.date.endDate, items, 'TotalFuelConsumed', 2);
                } else {
                    dataType = 'month';
                    result = this.initDataByMonth(this.date.startDate, this.date.endDate, items, 'TotalFuelConsumed', 2);
                }
                result.type = dataType;
                this.totalFuelConsumptionData.data = result.data;
                this.totalFuelConsumptionData.value = result.value;
                this.totalFuelConsumptionData.type = result.type;
                this.totalFuelConsumptionData.map.set(totalLineMapKey, Object.assign({}, {
                    data: result.data,
                    value: result.value,
                    type: result.type
                }));
            },
            getTotalMapLineKey(startDate, endDate) {
                return 'total-line-' + startDate + '-' + endDate;
            },
            initDataByDay(startDate, endDate, data, filedName, decimalPoints) {
                let dataMap = this.initDataMapByDay(startDate, endDate,filedName);
                if (filedName === "Hours") {
                    console.error(`dataMap = `, dataMap);
                }
                let totalValue = 0;
                let mathTmp = Math.pow(10, 6);
                decimalPoints = decimalPoints === undefined ? 0 : decimalPoints;
                data.forEach(items => {
                    items.Data.forEach(sonItems => {
                        let key = sonItems.Date;
                        if (dataMap.has(key)) {
                            let oldValue = dataMap.get(key).value;
                            let newValue = parseInt(sonItems[filedName] * mathTmp + 0.5);
                            if (oldValue <= 0) oldValue = 0;
                            if (newValue <= 0) newValue = 0;
                            totalValue += newValue;
                            dataMap.set(key, {
                                name: key,
                                value: parseFloat(((oldValue * mathTmp + newValue) / mathTmp).toFixed(decimalPoints + 1))
                            });
                        }
                    });
                });
                totalValue = parseFloat((totalValue / mathTmp).toFixed(decimalPoints));
                let dataResult = [];
                dataMap.forEach(item => {
                    dataResult.push(item);
                });
                return {
                    data: dataResult,
                    value: totalValue
                };
            },
            initDataByWeek(startDate, endDate, data, filedName, decimalPoints) {
                let dataMap = this.initDataMapByWeek(startDate, endDate);
                let totalValue = 0;
                let mathTmp = Math.pow(10, 6);
                decimalPoints = decimalPoints === undefined ? 0 : decimalPoints;
                data.forEach(items => {
                    items.Data.forEach(sonItems => {
                        let dateArr = sonItems.Date.split('-');
                        let dateObj = {
                            year: parseInt(dateArr[0]),
                            month: parseInt(dateArr[1]),
                            day: parseInt(dateArr[2])
                        };
                        let dayKey = this.formatYearMonthDayToStr(dateObj.year, dateObj.month, dateObj.day);
                        let weekKey = this.formatFirstDayOfWeek(dateObj.year, dateObj.month, dateObj.day);
                        let key = dataMap.has(dayKey) ? dayKey : weekKey;
                        if (dataMap.has(key)) {
                            let oldValue = dataMap.get(key).value;
                            let newValue = parseInt(sonItems[filedName] * mathTmp + 0.5);
                            if (oldValue <= 0) oldValue = 0;
                            if (newValue <= 0) newValue = 0;
                            totalValue += newValue;
                            dataMap.set(key, {
                                name: key,
                                value: parseFloat(((oldValue * mathTmp + newValue) / mathTmp).toFixed(decimalPoints + 1))
                            });
                        }
                    });
                });
                totalValue = parseFloat((totalValue / mathTmp).toFixed(decimalPoints));
                let dataResult = [];
                dataMap.forEach(item => {
                    dataResult.push(item);
                });
                return {
                    data: dataResult,
                    value: totalValue
                };
            },
            initDataByMonth(startDate, endDate, data, filedName, decimalPoints) {
                let dataMap = this.initDataMapByMonth(startDate, endDate);
                let totalValue = 0;
                let mathTmp = Math.pow(10, 6);
                decimalPoints = decimalPoints === undefined ? 0 : decimalPoints;
                data.forEach(items => {
                    items.Data.forEach(sonItems => {
                        let dateArr = sonItems.Date.split('-');
                        let dateObj = {year: parseInt(dateArr[0]), month: parseInt(dateArr[1])};
                        let key = this.formatYearMonthToStr(dateObj.year, dateObj.month);
                        if (dataMap.has(key)) {
                            let oldValue = dataMap.get(key).value;
                            let newValue = parseInt(sonItems[filedName] * mathTmp + 0.5);
                            if (oldValue <= 0) oldValue = 0;
                            if (newValue <= 0) newValue = 0;
                            totalValue += newValue;
                            dataMap.set(key, {
                                name: key,
                                value: parseFloat(((oldValue * mathTmp + newValue) / mathTmp).toFixed(decimalPoints + 1))
                            });
                        }
                    });
                });
                totalValue = parseFloat((totalValue / mathTmp).toFixed(decimalPoints));
                let dataResult = [];
                dataMap.forEach(item => {
                    dataResult.push(item);
                });
                return {
                    data: dataResult,
                    value: totalValue
                };
            },
            initDataMapByDay(startDate, endDate) {
                let dataMap = new Map();
                let start = startDate.split('-');
                let end = endDate.split('-');
                let startObj = {
                    year: parseInt(start[0]),
                    month: parseInt(start[1]),
                    day: parseInt(start[2])
                };
                let endObj = {
                    year: parseInt(end[0]),
                    month: parseInt(end[1]),
                    day: parseInt(end[2])
                };
                if (startObj.year < endObj.year) {
                    let endDay = new Date(startObj.year, startObj.month, 0).getDate();
                    for (let i = startObj.day; i <= endDay; i++) {
                        let dateStr = this.formatYearMonthDayToStr(startObj.year, startObj.month, i);
                        dataMap.set(dateStr, {name: dateStr, value: 0});
                    }
                    if (startObj.month < 12) {
                        for (let i = startObj.month + 1; i <= 12; i++) {
                            let maxDay = new Date(startObj.year, i, 0).getDate();
                            for (let j = 1; j <= maxDay; j++) {
                                let dateStr = this.formatYearMonthDayToStr(startObj.year, i, j);
                                dataMap.set(dateStr, {name: dateStr, value: 0});
                            }
                        }
                    }
                    if (endObj.month > 1) {
                        for (let j = 1; j < endObj.month; j++) {
                            let maxEndDay = new Date(endObj.year, j, 0).getDate();
                            for (let i = 1; i < maxEndDay; i++) {
                                let dateStr = this.formatYearMonthDayToStr(endObj.year, j, i);
                                dataMap.set(dateStr, {name: dateStr, value: 0});
                            }
                        }
                    }
                    for (let i = 1; i <= endObj.day; i++) {
                        let dateStr = this.formatYearMonthDayToStr(endObj.year, endObj.month, i);
                        dataMap.set(dateStr, {name: dateStr, value: 0});
                    }
                }
                if (startObj.year === endObj.year) {
                    let endDay = new Date(startObj.year, startObj.month, 0).getDate();
                    if (startObj.month === endObj.month) {
                        for (let i = startObj.day; i <= endObj.day; i++) {
                            let dateStr = this.formatYearMonthDayToStr(startObj.year, startObj.month, i);
                            dataMap.set(dateStr, {name: dateStr, value: 0});
                        }
                    } else {
                        for (let i = startObj.day; i <= endDay; i++) {
                            let dateStr = this.formatYearMonthDayToStr(startObj.year, startObj.month, i);
                            dataMap.set(dateStr, {name: dateStr, value: 0});
                        }
                        if (endObj.month - startObj.month !== 1 && endObj.month - startObj.month !== 0) {
                            for (let j = startObj.month + 1; j < endObj.month; j++) {
                                let maxEndDay = new Date(endObj.year, j, 0).getDate();
                                for (let i = 1; i <= maxEndDay; i++) {
                                    let dateStr = this.formatYearMonthDayToStr(endObj.year, j, i);
                                    dataMap.set(dateStr, {name: dateStr, value: 0});
                                }
                            }
                        }
                        let maxEndDay = new Date(endObj.year, endObj.month, endObj.day).getDate();
                        for (let i = 1; i <= maxEndDay; i++) {
                            let dateStr = this.formatYearMonthDayToStr(endObj.year, endObj.month, i);
                            dataMap.set(dateStr, {name: dateStr, value: 0});
                        }
                    }
                }
                return dataMap;
            },
            initDataMapByWeek(startDate, endDate) {
                let dataMap = new Map();
                let start = startDate.split('-');
                let end = endDate.split('-');
                let startObj = {
                    year: parseInt(start[0]),
                    month: parseInt(start[1]),
                    day: parseInt(start[2])
                };
                let endObj = {
                    year: parseInt(end[0]),
                    month: parseInt(end[1]),
                    day: parseInt(end[2])
                };
                let startTimestamp = new Date(startObj.year, startObj.month - 1, startObj.day).getTime();
                let endTimestamp = new Date(endObj.year, endObj.month - 1, endObj.day).getTime();
                let startDay = new Date(startObj.year, startObj.month - 1, startObj.day).getDay();
                let dateStr = this.formatYearMonthDayToStr(startObj.year, startObj.month, startObj.day);
                dataMap.set(dateStr, {name: dateStr, value: 0});
                let weekNum = 0, weekLoop = true;
                while (weekLoop) {
                    let diffDays = 7 - startDay + weekNum * 7;
                    let nextWeekTimestamp = new Date(startTimestamp + diffDays * 24 * 3600 * 1000).getTime();
                    if (nextWeekTimestamp > endTimestamp) {
                        weekLoop = false;
                        break;
                    }
                    let nextWeekDate = new Date(nextWeekTimestamp);
                    let nextYear = nextWeekDate.getFullYear();
                    let nextMonth = nextWeekDate.getMonth() + 1;
                    let nextDay = nextWeekDate.getDate();
                    dateStr = this.formatYearMonthDayToStr(nextYear, nextMonth, nextDay);
                    dataMap.set(dateStr, {name: dateStr, value: 0, start: startDate, end: endDate});
                    weekNum++;
                }
                return dataMap;
            },
            initDataMapByMonth(startDate, endDate) {
                let dataMap = new Map();
                let start = startDate.split('-');
                let end = endDate.split('-');
                let startObj = {
                    year: parseInt(start[0]),
                    month: parseInt(start[1]),
                    day: parseInt(start[2])
                };
                let endObj = {
                    year: parseInt(end[0]),
                    month: parseInt(end[1]),
                    day: parseInt(end[2])
                };
                if (startObj.year < endObj.year) {
                    for (let month = startObj.month; month <= 12; month++) {
                        let yearMonthStr = this.formatYearMonthToStr(startObj.year, month);
                        dataMap.set(yearMonthStr, {name: yearMonthStr, value: 0});
                    }
                    for (let year = startObj.year + 1; year <= endObj.year - 1; year++) {
                        for (let month = 1; month <= 12; month++) {
                            let yearMonthStr = this.formatYearMonthToStr(year, month);
                            dataMap.set(yearMonthStr, {name: yearMonthStr, value: 0});
                        }
                    }
                    for (let month = 1; month <= endObj.month; month++) {
                        let yearMonthStr = this.formatYearMonthToStr(endObj.year, month);
                        dataMap.set(yearMonthStr, {name: yearMonthStr, value: 0});
                    }
                }
                if (startObj.year === endObj.year) {
                    for (let month = startObj.month; month <= endObj.month; month++) {
                        let yearMonthStr = this.formatYearMonthToStr(startObj.year, month);
                        dataMap.set(yearMonthStr, {name: yearMonthStr, value: 0});
                    }
                }
                return dataMap;
            },
            formatDateToStr(date) {
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                month = month < 10 ? '0' + month : '' + month;
                let day = date.getDate();
                day = day < 10 ? '0' + day : '' + day;
                return '' + year + '-' + month + '-' + day;
            },
            formatYearMonthDayToStr(year, month, day) {
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + day : '' + day;
                return year + '-' + month + '-' + day;
            },
            formatYearMonthToStr(year, month) {
                month = month < 10 ? '0' + month : '' + month;
                return year + '-' + month;
            },
            formatFirstDayOfWeek(year, month, day) {
                let currentDate = new Date(year, month - 1, day);
                let currentWeekDay = new Date(currentDate.getTime() - currentDate.getDay() * 24 * 3600 * 1000);
                let weekYear = currentWeekDay.getFullYear();
                let weekMonth = currentWeekDay.getMonth() + 1;
                let weekDay = currentWeekDay.getDate();
                return this.formatYearMonthDayToStr(weekYear, weekMonth, weekDay)
            },
            //let decimalPoint = {avg: 0, avgValue: 0, total: 0, totalValue: 0};
            //(data.Fleets, this.date.lastDay, 'CollisionCount', decimalPoint)
            formatApiData2Total(items, lastDays, filedName, decimalPoint) {
                let point = {avg: 0, avgValue: 0, total: 0, totalValue: 0};
                if (decimalPoint && decimalPoint.avg !== undefined) point.avg = decimalPoint.avg;
                if (decimalPoint && decimalPoint.avgValue !== undefined) point.avgValue = decimalPoint.avgValue;
                if (decimalPoint && decimalPoint.total !== undefined) point.total = decimalPoint.total;
                if (decimalPoint && decimalPoint.totalValue !== undefined) point.totalValue = decimalPoint.totalValue;
                lastDays = lastDays > 0 ? lastDays : 0;
                let total = [];
                let sumValue = 0;
                let avg = [];
                let avgValue = 0;
                let mathTmp = Math.pow(10, 8);
                let totalTripCount = 0;
                items.forEach(item => {
                    let color = this.fleet.colorListMap.get(item.FleetId);
                    let totalData = {name: item.FleetName, value: 0, color: color};
                    let avgData = {name: item.FleetName, value: 0, color: color};
                    let tripCount = 0;
                    if (typeof item.TripCount === 'undefined') {
                        tripCount = item.Data.length;
                        totalTripCount += item.Data.length;
                    } else {
                        tripCount = item.TripCount;
                        totalTripCount += item.TripCount;
                    }
                    item.Data.forEach(dateHourObj => {
                        let newFileNameValue = parseInt(dateHourObj[filedName] * mathTmp + 0.5);
                        if (!isNaN(dateHourObj[filedName]) && dateHourObj[filedName] > 0) {
                            totalData.value += newFileNameValue;
                            sumValue += newFileNameValue;
                        }
                    });
                    avgData.value = tripCount === 0 ?
                        this.$t('globalText.na') : parseFloat((totalData.value / (mathTmp * tripCount)).toFixed(point.avg));
                    totalData.value = parseFloat((totalData.value / mathTmp).toFixed(point.total));
                    total.push(totalData);
                    avg.push(avgData)
                });
                avgValue = parseFloat((sumValue / (mathTmp * totalTripCount)).toFixed(point.avgValue));
                sumValue = parseFloat((sumValue / mathTmp).toFixed(point.totalValue));
                return {
                    total: {
                        data: this.sortDataList(total),
                        value: sumValue,
                    },
                    avg: {
                        data: this.sortDataList(avg),
                        value: avgValue,
                    }
                };
            },
            formatApiFleetFuelConsumptionData(items, decimalPoint) {
                let point = {avg: 0, avgValue: 0, total: 0, totalValue: 0};
                if (decimalPoint && decimalPoint.avg !== undefined) point.avg = decimalPoint.avg;
                if (decimalPoint && decimalPoint.avgValue !== undefined) point.avgValue = decimalPoint.avgValue;
                if (decimalPoint && decimalPoint.total !== undefined) point.total = decimalPoint.total;
                if (decimalPoint && decimalPoint.totalValue !== undefined) point.totalValue = decimalPoint.totalValue;
                let totalFuel = 0,
                    totalDistance = 0;
                let total = [],
                    avg = [];
                let mathTmp = Math.pow(10, 8);
                items.forEach(item => {
                    let color = this.fleet.colorListMap.get(item.FleetId);
                    let totalData = {name: item.FleetName, value: 0, color: color};
                    let avgData = {name: item.FleetName, value: 0, color: color};
                    let sonTotalFuel = 0, sonTotalDistance = 0;
                    item.Data.forEach(fuelObj => {
                        if (!isNaN(fuelObj.Distance) && fuelObj.Distance > 0) {
                            sonTotalDistance += parseInt(fuelObj.Distance * mathTmp + 0.5);
                        }
                        if (!isNaN(fuelObj.TotalFuelConsumed) && fuelObj.TotalFuelConsumed > 0) {
                            sonTotalFuel += parseInt(fuelObj.TotalFuelConsumed * mathTmp + 0.5);
                        }
                    });
                    totalData.value = (Math.round(sonTotalFuel / mathTmp * Math.pow(10, point.total)) / Math.pow(10, point.total)).toFixed(point.total);
                    avgData.value = sonTotalFuel === 0
                        ? this.$t('globalText.na')
                        : (Math.round(sonTotalDistance / sonTotalFuel * mathTmp) / mathTmp).toFixed(point.avg);
                    totalFuel += sonTotalFuel;
                    totalDistance += sonTotalDistance;
                    total.push(totalData);
                    avg.push(avgData);
                });
                return {
                    total: {
                        data: this.sortDataList(total),
                        value: parseFloat((totalFuel / mathTmp).toFixed(point.totalValue))
                    },
                    avg: {
                        data: this.sortDataList(avg),
                        value: totalFuel === 0
                            ? this.$t('globalText.na')
                            : (Math.round(totalDistance / totalFuel * mathTmp) / mathTmp).toFixed(point.avgValue)
                    }
                };
            },
            initDate() {
                let end = new Date();
                let endTime = end.getTime();
                let start = new Date(endTime - this.date.lastDay * 24 * 3600 * 1000);
                this.date.endDate = this.formatDateToStr(end);
                this.date.startDate = this.formatDateToStr(start);
                this.date.showDataByDayRange.push(this.date.endDate);
                this.date.showDataByDayRange.push(this.formatDateToStr(new Date(endTime - 30 * 24 * 3600 * 1000)));
                // this.date.showDataByDayRange.push(this.formatDateToStr(new Date(endTime - 31 * 24 * 3600 * 1000)));
                this.date.showDataByDayRange.push(this.formatDateToStr(new Date(endTime - 7 * 24 * 3600 * 1000)));
            },
            showDateType(startDate, endDate) {
                let startArr = startDate.split('-');
                let startObj = {year: parseInt(startArr[0]), month: parseInt(startArr[1]), day: parseInt(startArr[2])};
                let endArr = endDate.split('-');
                let endObj = {year: parseInt(endArr[0]), month: parseInt(endArr[1]), day: parseInt(endArr[2])};
                let lastDays = (new Date(endObj.year, endObj.month - 1, endObj.day) - new Date(startObj.year, startObj.month - 1, startObj.day)) / 1000 / 3600 / 24;
                let type = 'day';
                if (lastDays < 31) {
                    type = 'day';
                } else if (lastDays < 84) {
                    type = 'week';
                } else {
                    type = 'month';
                }
                return type;
            },
            initAllChartDataToZero() {
                this.hoursDrivenData.total.data = [];
                this.hoursDrivenData.total.value = 0;
                this.hoursDrivenData.avg.data = [];
                this.hoursDrivenData.avg.value = 0;
                this.hoursDrivenData.line = [];

                this.distanceDriverData.total.data = [];
                this.distanceDriverData.total.value = 0;
                this.distanceDriverData.avg.data = [];
                this.distanceDriverData.avg.value = 0;
                this.distanceDriverData.line = [];

                this.fuelConsumptionData.total.data = [];
                this.fuelConsumptionData.total.value = 0;
                this.fuelConsumptionData.avg.data = [];
                this.fuelConsumptionData.avg.value = 0;
                this.fuelConsumptionData.line = [];

                this.collisionAlertsData.total.data = [];
                this.collisionAlertsData.total.value = 0;
                this.collisionAlertsData.avg.data = [];
                this.collisionAlertsData.avg.value = 0;

                this.safetyScoreData.total.data = [];
                this.safetyScoreData.total.value = 0;
                this.safetyScoreData.avg.data = [];
                this.safetyScoreData.avg.value = 0;

                this.rankingByVehicleData.data = [];
                this.rankingByDriverData.data = [];

                this.totalHoursDrivenData.data = [];
                this.totalHoursDrivenData.value = 0;
                this.totalDistanceDrivenData.data = [];
                this.totalDistanceDrivenData.value = 0;
                this.totalFuelConsumptionData.data = [];
                this.totalFuelConsumptionData.value = 0;
            },
            changeLastDays(startDay, endDay) {
                let startDayArr = startDay.split('-');
                let endDayArr = endDay.split('-');
                let startTime = new Date(parseInt(startDayArr[0]), parseInt(startDayArr[1]), parseInt(startDayArr[2])).getTime();
                let endTime = new Date(parseInt(endDayArr[0]), parseInt(endDayArr[1]), parseInt(endDayArr[2])).getTime();
                this.date.lastDay = (endTime - startTime) / (24 * 60 * 60 * 1000) + 1;
            },
            formatNumToString(value, point) {
                if (isNaN(value)) return this.$t('globalText.na');
                let numTmp = Math.pow(10, point);
                if (value >= 1000000) {
                    value = value / 1000000;
                    value = Math.round(value * numTmp) / numTmp;
                    return value.toFixed(point) + 'M';
                }
                if (value >= 1000) {
                    value = value / 1000;
                    value = Math.round(value * numTmp) / numTmp;
                    return value.toFixed(point) + 'K';
                }
                value = Math.round(value * numTmp) / numTmp;
                return value.toFixed(point);
            },
            formatNumToStringForAlerts(value, point) {
                if (isNaN(value)) return this.$t('globalText.na');
                if (value >= 1000) return this.formatNumToString(value, point);
                return value.toFixed(0);
            },
            numRound(value, point) {
                if (isNaN(value)) return value;
                let numTmp = Math.pow(10, point);
                return Math.round(value * numTmp) / numTmp;
            },
            sortDataList(dataList) {
                let newList = [];
                newList = dataList.sort((itemA, itemB) => {
                    if (itemA.value === 'N/A' && itemB.value === 'N/A')
                        return Sort.NameSort(itemA.name, itemB.name);
                    if (itemA.value === 'N/A') return 1;
                    if (itemB.value === 'N/A') return -1;
                    let valueA = parseFloat(itemA.value),
                        valueB = parseFloat(itemB.value);
                    if (valueA > valueB) return -1;
                    if (valueA < valueB) return 1;
                    return Sort.NameSort(itemA.name, itemB.name);
                });
                return newList;
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    @media only screen and (max-width: 1830px) {
        .top-chart {
            height 270px !important;
        }
    }

    TH, TD {
        font-family: "Calibri";
        font-size: 12pt;
        border: 1px solid;
        border-color: black;
        vertical-align: middle;
    }

    #data-table {
        border: 1px solid;
        border-collapse: collapse;
    }

    /* assign widths to cells in first row */
    #A01 {
        width: 150px;
    }

    #B01 {
        width: 100px;
    }

    #C01 {
        width: 180px;
    }

    #D01 {
        width: 180px;
    }

    * {
        box-sizing: border-box;
    }

    .bold {
        font-weight: bold;
    }

    .italic {
        font-style: italic;
    }

    .uline {
        text-decoration: underline;
    }

    .strike {
        text-decoration: line-through;
    }

    .times {
        font-family: "Times";
    }

    .px18 {
        font-size: 18px;
    }

    .wrap {
        white-space: pre-line;
    }

    .sup {
        vertical-align: super;
        font-size: .83em;
    }

    .sub {
        vertical-align: sub;
        font-size: .83em;
    }

    .rtext {
        color: red;
    }

    .gtext {
        color: green;
    }

    .left {
        text-align: left;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .top {
        vertical-align: top;
    }

    .middle {
        vertical-align: middle;
    }

    .bottom {
        vertical-align: bottom;
    }


    $space-width = 16px
    $space-height = 10px
    $bottom-space-height = 4px
    $box-bottom-height = 586px
    $chart-padding-top = 10px
    $chart-padding-bottom = 10px
    $chart-padding-left = 10px
    $chart-padding-right = 10px
    .dashboard-date-selector
        margin-bottom: 10px;
        position: absolute;
        top: -55px;
        left: 250px;

    .dashboard-page-main-v4
        background-color $color_gray_third

        .dashboard-page-main-v4-box
            height_bak 825px - 60px
            padding 0px 15px 0px 0px
            margin-bottom 0px

            .border-radius
                border-radius 5px

            > .dashboard-top
                margin-bottom $space-height

            > .dashboard-middle
                margin-bottom $space-height
                display: flex;
                display: -webkit-flex;
                align-items: flex-start;
                flex-direction row
                justify-content: space-between;
                flex-wrap: nowrap

                > .dashboard-flex-box
                    width calc(20% - 16px)

                    > .top-chart
                        height 210px
                        padding $chart-padding-top $chart-padding-right 0px $chart-padding-left
                        overflow_bak hidden

            > .dashboard-bottom
                height $box-bottom-height
                display: flex;
                display: -webkit-flex;
                align-items: flex-start;
                flex-direction row
                justify-content: space-between;
                flex-wrap: nowrap

                .dashboard-left, .dashboard-right
                    height 100%

                > .dashboard-left
                    width calc(60% - 8px)

                    .dashboard-total-chart
                        padding $chart-padding-top $chart-padding-right $chart-padding-bottom $chart-padding-left
                        height calc(100% - 5px)

                > .dashboard-right
                    width calc(40% - 12px)

                    > .dashboard-top, > .dashboard-bottom
                        height calc(50% - 5px)
                        overflow hidden

                    > .dashboard-top
                        margin-bottom $space-height

                .dashboard-ranking-table
                    background-color $color_white
                    padding $chart-padding-top + 0px $chart-padding-right 0 $chart-padding-left
                    height calc(100% - 5px)
</style>
