<template>
    <div class="dashboard-com-top-value display-flex">
        <span class="com-top-value-num" :title="formatNum(valueNum)">{{(typeof value === 'number' && isNaN(value)) ? $t('globalText.na') : value }}</span>
    </div>
</template>

<script>
    export default {
        name: "ComTopValue",
        props: {
            value: {
                required: true
            },
            valueNum: {
                type: [Number, String],
            },
            point: {
                type: Number,
                default: 1
            }
        },
        methods: {
            formatNum(num) {
                if (typeof num !== 'number' || isNaN(num)) return this.$t('globalText.na');
                let numTmp = Math.pow(10, this.point);
                num = Math.round(numTmp * num) / numTmp;
                return num.toFixed(this.point);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-value = $color_black_first

    @media only screen and (max-width: 1600px) {
        .dashboard-com-top-value_bak {
            font-size 20px !important
        }
    }

    @media only screen and (max-width: 1440px) {
        .dashboard-com-top-value_bak {
            font-size 14px !important
        }
    }

    .dashboard-com-top-value
        text-align center
        height 100%
        color $color_black_first
        font-size_bak 38px
        font-size 32px
        font-weight 400

    .dashboard-com-top-value:hover
        cursor default

    .display-flex
        display: flex;
        display: -webkit-flex;
        align-items: center;
        flex-direction: column
        justify-content: space-around;
</style>
