<template>
    <div class="dashboard-com-char-line">
        <div class="com-char-box" ref="ryChartBox"></div>
        <div class="com-char-line-top">
            <span class="com-char-line-img">
                <slot></slot>
            </span>
            <span class="com-char-line-title">{{ title }}</span>
            <span class="com-char-line-value">{{ value }}</span>
            <span class="com-char-line-unit">{{ unitAbbr }}</span>
            <span class="com-char-line-date-range">{{ formatDateToEn(this.startDate) }} – {{ formatDateToEn(this.endDate) }}</span>
        </div>
    </div>
</template>

<script>
    import bus from "../../utils/bus";
    import echarts from 'echarts/lib/echarts';
    import 'echarts/lib/chart/line';
    import 'echarts/lib/component/tooltip';
    import 'echarts/lib/component/grid';
    import 'echarts/lib/component/dataZoom';
    import 'echarts/lib/component/dataZoomInside';
    import 'echarts/lib/component/markPoint';

    export default {
        name: "ComChartLine",
        props: {
            data: {
                required: true,
                type: Array
            },
            color: {
                type: Object,
                required: true
            },
            title: {
                type: String,
                required: true,
            },
            value: {
                // type: Number,
                required: true
            },
            unit: {
                type: String,
                required: true
            },
            unitAbbr: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            startDate: {
                type: [String, Object],
                required: true
            },
            endDate: {
                type: [String, Object],
                required: true
            },
            unitChoice: {
                type: Object,
                required: true
            }
        },
        mounted() {
            this.$nextTick(() => {
                //this.initChart();
            });
            bus.$on('chartResize', this.resize);
        },
        data() {
            return {
                yearMonthReg: /^[1-9][0-9]{3}-[0-1][0-9]$/,
                yearMonthDayReg: /^[1-9][0-9]{3}-[0-1][0-9]-[0-9]{2}$/,

                chart: null,
                currentColor: this.color,
                noData: false,
                maxValue: -1,
                minValue: -1,
                chartOption: {
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        hideDelay: 0,
                        transitionDuration:0.0,
                    },
                    grid: {
                        containLabel: true,
                        left: '20px',
                        right: '35px',
                        top: '50px',
                        bottom: '0px',
                        tooltip: {
                            show: true,
                            trigger: 'item',
                            transitionDuration:0.0,
                            formatter: (params) => {
                                let date = params.name;
                                let value = params.data;
                                let unit = this.unitChoice.plural || '';
                                if (parseInt(value) === 1) {
                                    unit = this.unitChoice.singular || '';
                                }
                                let dateStr = this.formatDateToEn(date);
                                return value + ' ' + unit + '<br />' + dateStr;
                            }
                        },
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        splitLine: {show: false},

                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#666666"
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            showMinLabel: true,
                            showMaxLabel: true,
                            formatter: (value, index) => {
                                return this.getXValue(value);
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Hours',
                        splitLine: {show: false},
                        axisLine: {
                            lineStyle: {
                                color: "#666666"
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    dataZoom: {
                        type: 'inside',
                    },
                    lineStyle: {
                        color: '#666666',
                    },
                    itemStyle: {
                        color: '#666666',
                    },
                    series: [{
                        type: 'line',
                        lineStyle: {
                            width: 1,
                        },
                        itemStyle: {
                            borderWidth: 4
                        },
                        label_bak: {
                            show: true,
                            fontWeight: 'bolder',
                            formatter: (object) => {
                                if (object.value === this.maxValue || object.value === this.minValue) {
                                    return object.value;
                                }
                                return '';
                            }
                        },
                        markPoint: {
                            data: [
                                {
                                    type: 'max',
                                    name: 'max',
                                    symbol: 'path://',
                                    label: {
                                        color: '#4c4c4c',
                                        fontWeight: 600,
                                        position: 'top',
                                        backgroundColor: '#ffffff',
                                        fontSize: 16,
                                        formatter: (object) => {
                                            if (object.value === 0) {
                                                return '';
                                            }
                                            return object.value.toFixed(1);
                                        }
                                    }
                                },
                                {
                                    type: 'min',
                                    name: 'min',
                                    symbol: 'path://',
                                    label: {
                                        color: '#4c4c4c',
                                        fontWeight: 'bolder',
                                        fontSize: 16,
                                        position: 'top',
                                        backgroundColor: '#ffffff',
                                        formatter: (object) => {
                                            if (object.value === 0 || this.data.length <= 1) {
                                                return '';
                                            }
                                        }
                                    }
                                },
                            ]
                        },
                        data: null
                    }]
                }
            }
        },
        computed: {
            lastDateStr() {
                let length = this.data.length;
                return this.data[length - 1].end;
            },
            currentUnit() {
                return this.unit;
            },
            monthMap(){
                return new Map([
                    [1, this.$t('dashboard.jan')],
                    [2, this.$t('dashboard.feb')],
                    [3, this.$t('dashboard.mar')],
                    [4, this.$t('dashboard.apr')],
                    [5, this.$t('dashboard.may')],
                    [6, this.$t('dashboard.jun')],
                    [7, this.$t('dashboard.Jul')],
                    [8, this.$t('dashboard.aug')],
                    [9, this.$t('dashboard.sep')],
                    [10,this.$t('dashboard.oct')],
                    [11,this.$t('dashboard.nov')],
                    [12,this.$t('dashboard.dec')]
                ])
            } ,
        },
        watch: {
            data(val) {
                if (val && val.length >=0)
                    this.initChart();
            },
            unit() {
                this.initChart();
            }
            // currentColor() {
            //     this.initChart();
            // }
        },
        methods: {
            getXValue(value, index) {
                if (typeof value !== 'string') return value;
                let yearMonthPath = this.yearMonthReg;
                let yearMonthDayPath = this.yearMonthDayReg;
                if (yearMonthPath.test(value)) {
                    let ymdArr = value.split('-');
                    let month = parseInt(ymdArr[1]);
                    if (this.monthMap.has(month)) {
                        return this.monthMap.get(month);
                    }
                }
                if (yearMonthDayPath.test(value)) {
                    let ymdArr = value.split('-');
                    let month = parseInt(ymdArr[1]);
                    if (this.monthMap.has(month)) {
                        return this.monthMap.get(month) + '. ' + ymdArr[2];
                    }
                }
                return value;
            },
            formatDateToEn(date) {
                if (this.yearMonthReg.test(date)) {
                    let dateArr = date.split('-');
                    if (this.monthMap.has(parseInt(dateArr[1]))) {
                        let month = this.monthMap.get(parseInt(dateArr[1]));
                        return month + '., ' + dateArr[0];
                    }
                }
                if (this.yearMonthDayReg.test(date)) {
                    let dateArr = date.split('-');
                    if (this.monthMap.has(parseInt(dateArr[1]))) {
                        let month = this.monthMap.get(parseInt(dateArr[1]));
                        return month + '. ' + dateArr[2] + ', ' + dateArr[0];
                    }
                }
                return date;
            },
            resize() {
                if (this.chart) {
                    this.chart.resize();
                }
            },
            initChart() {
                let xData = [];
                let yData = [];
                let length = this.data.length;
                for (let i = 0; i < length; i++) {
                    xData[i] = this.data[i].name;
                    yData[i] = this.data[i].value;
                }
                this.chartOption.tooltip.formatter = (params) => {
                    if (this.type === 'week') {
                        let weekDate = this.getStartDayAndEndDayForWeek(params.name, this.lastDateStr);
                        return weekDate.start + ' ~ ' + weekDate.end + ': ' + params.value;
                    }
                    return params.name + ': ' + params.value;
                };
                this.chartOption.xAxis.data = xData;
                this.chartOption.yAxis.name = this.currentUnit;

                this.chartOption.lineStyle.color = this.currentColor.lineColor;
                this.chartOption.itemStyle.color = this.currentColor.itemColor;
                this.chartOption.series[0].data = yData;
                // this.chartOption.series[0].markPoint.data[0].label.color = this.currentColor;
                // this.chartOption.series[0].markPoint.data[1].label.color = this.currentColor;
                this.maxValue = Math.max(...yData);
                this.minValue = Math.min(...yData);
                this.makeMap();
            },
            makeMap() {
                this.checkData();
                if (!this.chart) {
                    this.chart = echarts.init(this.$refs.ryChartBox);
                }
                this.chart.setOption(this.chartOption, true);
            },
            checkData() {
                if (!this.data || typeof this.data === 'string' || Object.keys(this.data).length === 0) {
                    this.noData = true;
                } else {
                    this.noData = false;
                }
            },
            getStartDayAndEndDayForWeek(dateStr, endDateStr) {
                let dateArr = dateStr.split('-'),
                    endDateArr = endDateStr.split('-'),
                    dateObj = {year: parseInt(dateArr[0]), month: parseInt(dateArr[1]), day: parseInt(dateArr[2])},
                    endDateObj = {
                        year: parseInt(endDateArr[0]),
                        month: parseInt(endDateArr[1]),
                        day: parseInt(endDateArr[2])
                    },
                    currentDate = new Date(dateObj.year, dateObj.month - 1, dateObj.day),
                    endDateTimestamp = new Date(endDateObj.year, endDateObj.month - 1, endDateObj.day).getTime(),
                    nextDate = new Date(currentDate.getTime() + (6 - currentDate.getDay()) * 24 * 3600 * 1000),
                    end = endDateStr;
                if (endDateTimestamp > nextDate.getTime()) {
                    let year = nextDate.getFullYear(),
                        month = nextDate.getMonth() + 1,
                        day = nextDate.getDate();
                    year = '' + year;
                    month = month < 10 ? '0' + month : '' + month;
                    day = day < 10 ? '0' + day : '' + day;
                    end = year + '-' + month + '-' + day;
                }
                return {
                    start: dateStr,
                    end: end
                };
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $font-color-title = $color_black_first
    $font-color-content = $color_black_first
    $font-color-value = $color_black_first

    .dashboard-com-char-line
        position relative
        height 100%
        overflow hidden

        .com-char-box
            height 100%

        .com-char-line-top
            position absolute
            top 2px
            left 90px
            right 0

            .com-char-line-img, .com-char-line-title, .com-char-line-value, .com-char-line-unit
                display inline-block
                cursor default

            .com-char-line-title, .com-char-line-value, .com-char-line-unit, .com-char-line-date-range
                font-size 17px
                position relative
                top -5px

            .com-char-line-img
                margin-right 5px
                width 22px
                height 22px
            .com-char-line-title
                color $font-color-title
                margin-right 5px

            .com-char-line-value
                color $font-color-value
                margin-right 5px
                font-weight 600

            .com-char-line-unit
                color $font-color-value
                font-weight 600
            .com-char-line-date-range
                left 10px
                font-size 12px
                font-weight 600
</style>
