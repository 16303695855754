<template>
    <div class="dashboard-com-ranking-by-vehicle">
        <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                width="1300px">
            <el-table
                    :data="currDataList"
                    :default-sort="{prop: 'score', order: 'descending'}"
                    @sort-change="handleSortChange"
                    size="mini"
                    header-row-class-name="rbv-table-header-row"
                    stripe
                    height="100%"
                    style="width: 100%; max-height: 560px; padding-top: 10px;">
                <el-table-column
                        min-width="104px"
                        prop="name"
                        sortable="custom"
                        :sort-orders="['descending', 'ascending']"
                        :label="vehicleNameTitle">
                    <template slot-scope="scope">
                        <div class="com-ranking-by-vehicle-text-box">
                            <span class="ry-color-block via-vehicles"
                                  :title="scope.row.fleetName === 'Unassigned Vehicles' ? $t('dashboard.unassigned') : scope.row.fleetName"
                                  :style="{ color: scope.row.color }"></span>
                            <span :title="scope.row.name">{{ scope.row.name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        min-width="85px"
                        prop="time"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="timeTitle">
                    <template slot-scope="scope">
                        {{ formatNumToString(scope.row.time, 1) }}
                    </template>
                </el-table-column>
                <el-table-column
                        min-width="114px"
                        prop="distance"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="distanceTitle">
                    <template slot-scope="scope">
                        {{ formatNumToString(scope.row.distance, 1) }}
                    </template>
                </el-table-column>
                <el-table-column
                        min-width="80px"
                        prop="fuel"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="fuelTitle">
                    <template slot-scope="scope">
                        {{ formatNumToString(scope.row.fuel, 1) }}
                    </template>
                </el-table-column>
                <el-table-column
                        min-width="100px"
                        prop="collisionCount"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="collisionAlertTitle">
                </el-table-column>
                <el-table-column
                        min-width="90px"
                        prop="driverAlertCount"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="driverAlertTitle">
                </el-table-column>
                <el-table-column
                        min-width="90px"
                        prop="parkingCollisionCount"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="parkingAlertTitle">
                </el-table-column>
                <el-table-column
                        min-width="115px"
                        prop="unpermittedCount"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="unpermittedDriverTitle">
                </el-table-column>
                <el-table-column
                        min-width="99px"
                        prop="score"
                        sortable
                        :sort-orders="['descending', 'ascending']"
                        :label="driverScoreTitle">
                    <template slot-scope="scope">
                        {{ formatNumToString(scope.row.score, 1) }}
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <div class="com-ranking-by-vehicle-main">
            <com-ranking-title :title="title" :more="showMore" @more-action="handleMore">
                <span class="via-car ry-font"></span>
                <!--                <img src="../../../assets/image/dashboard/bottom_ranking_by_vehicle_title.jpg" height="26" width="26"/>-->
            </com-ranking-title>
            <div class="com-ranking-by-vehicle-table-box-main com-ranking-by-vehicle-scroll">
                <div class="com-ranking-by-vehicle-table-box ">
                    <el-table
                            :data="currDataList"
                            :default-sort="defaultSort"
                            @sort-change="handleSortChange"
                            size="mini"
                            header-row-class-name="rbv-table-header-row"
                            stripe
                            style="width: 100%">
                        <el-table-column
                                min-width="119px"
                                prop="name"
                                sortable="custom"
                                :sort-orders="['descending', 'ascending']"
                                :label="vehicleNameTitle"
                                :render-header="labelHead"
                        >
                            <template slot-scope="scope">
                                <div class="com-ranking-by-vehicle-text-box">
                                    <span class="ry-color-block via-vehicles"
                                          :title="scope.row.fleetName === 'Unassigned Vehicles' ? $t('dashboard.unassigned') : scope.row.fleetName"
                                          :style="{ color: scope.row.color }"></span>
                                    <span :title="scope.row.name">{{ scope.row.name }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                min-width="95px"
                                prop="time"
                                sortable
                                :sort-orders="['descending', 'ascending']"
                                :render-header="labelHead"
                                :label="timeTitle">
                            <template slot-scope="scope">
                                {{ formatNumToString(scope.row.time, 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                width="114px"
                                prop="distance"
                                sortable
                                :sort-orders="['descending', 'ascending']"
                                :render-header="labelHead"
                                :label="distanceTitle">
                            <template slot-scope="scope">
                                {{ formatNumToString(scope.row.distance, 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                min-width="85px"
                                prop="fuel"
                                sortable
                                :sort-orders="['descending', 'ascending']"
                                :render-header="labelHead"
                                :label="fuelTitle">
                            <template slot-scope="scope">
                                {{ formatNumToString(scope.row.fuel, 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                min-width="70px"
                                prop="alert"
                                sortable
                                :sort-orders="['descending', 'ascending']"
                                :render-header="labelHead"
                                :label="$t('tripHistory.alerts')">
                        </el-table-column>
                        <el-table-column
                                min-width="99px"
                                prop="score"
                                sortable
                                :sort-orders="['descending', 'ascending']"
                                :render-header="labelHead"
                                :label="driverScoreTitle">
                            <template slot-scope="scope">
                                {{ formatNumToString(scope.row.score, 1) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ComRankingTitle from './ComRankingTitle';
    import Sort from "../../assets/js/Sort";

    export default {
        name: "ComRankingByVehicle",
        props: {
            data: {
                type: Array,
                required: true
            }
        },
        components: {
            ComRankingTitle
        },
        data() {
            return {
                title_bak: 'Rankings by Vehicle',
                dialogVisible: false,
                defaultSort: {prop: 'score', order: 'descending'},
                lastSortOrder: null,
                lastSortObj: null,
                currDataList: []
            }
        },
        computed: {
            vehicleNameTitle() {
                return this.$t('dashboard.vehicleName');
            },
            timeTitle() {
                return this.$t('dashboard.time') + ' ('+ this.$t('units.hrs') +')';
            },
            distanceTitle() {
                return this.$t('dashboard.distance') + ' (' + this.unitKm + ')';
            },
            fuelTitle() {
                return this.$t('dashboard.fuel') + ' (' + this.unitL + ')';
            },
            alertsTitle() {
                return '';
            },
            collisionAlertTitle() {
                return this.$tc('dashboard.collisionAlerts', 2);
            },
            driverAlertTitle() {
                return this.$tc('dashboard.driverAlert', 2);
            },
            parkingAlertTitle() {
                return this.$tc('dashboard.parkingAlerts', 2);
            },
            unpermittedDriverTitle() {
                return this.$tc('dashboard.unpermittedDriver', 2);
            },
            driverScoreTitle() {
                return this.$t('dashboard.driverScore');
            },
            title() {
                return this.$t('dashboard.rankingsByVehicle');
            },
            showMore: function () {
                return this.data.length > 5 && false;
            },
            dataList: function () {
                this.data.forEach(item => {
                    if (!item.name) {
                        item.name = '';
                    }
                });
                return this.data;
            },
            unitKm() {
                return this.$unit.getKmUnit(this);
            },
            unitL() {
                return this.$unit.getLUnit(this);
            }
        },
        watch: {
            dataList(list) {
                this.currDataList = Object.assign([], list);
                if (list)
                    this.handleSortChange(this.defaultSort);
            }
        },
        methods: {
            handleMore() {
                this.dialogVisible = true
            },
            formatNumToString(value, point) {
                let tmpNum = Math.pow(10, point);
                value = Math.round(value * tmpNum) / tmpNum;
                return value.toFixed(point);
            },
            labelHead(h, {column, index}) {
                return h('span', {attrs: {class: 'table-head', title: column.label}}, [column.label])
            },
            handleSortChange(data) {
                if (this.lastSortObj && this.lastSortObj.prop !== data.prop) {
                    if (this.lastSortObj.column)
                        this.lastSortObj.column.order = null;
                    this.lastSortObj.order = null;
                }
                const ASCE = 'ascending', DESC = 'descending';
                if (data.order === null) {
                    if (this.lastSortOrder === null)
                        this.lastSortOrder = ASCE;
                    else
                        this.lastSortOrder = this.lastSortOrder === 'ascending' ? DESC : ASCE;
                    data.column.order = this.lastSortOrder;
                } else {
                    this.lastSortOrder = data.order;
                }
                let tagNum = this.lastSortOrder === ASCE ? 1 : -1;
                this.currDataList.sort((A, B) => {
                    if (data.prop === 'name')
                        return tagNum * Sort.NameSort(A.name.trim(), B.name.trim());
                    else {
                        if (A[data.prop] == B[data.prop]) {
                            return  Sort.NameSort(A.name.trim(), B.name.trim());
                        }
                        else
                            return tagNum * (A[data.prop] - B[data.prop]);
                    }

                });
                this.lastSortObj = data;
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $second-blue = $color_blue_second
    .dashboard-com-ranking-by-vehicle
        height 100%
        min-height 269px

        >>> .rbv-table-header-row th
            background-color $second-blue;
            color $color_white

        >>> .el-table .ascending .sort-caret.ascending,
        >>> .el-table .descending .sort-caret.descending
            border-top-color rgba(0, 0, 0, 0)
            border-bottom-color rgba(0, 0, 0, 0)
            font-family: 'via' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

        >>> .el-table .descending .sort-caret.descending::before
            content "\e940"
            color #ffffff
            position relative
            top -11px
            left -5px

        >>> .el-table .ascending .sort-caret.ascending::before
            content "\e93f"
            color #ffffff
            position relative
            top 0
            left -5px

        >>> .el-table .ascending .sort-caret.ascending, >>> .el-table .ascending .sort-caret.descending,
        >>> .el-table .descending .sort-caret.ascending, >>> .el-table .descending .sort-caret.descending
            border-bottom-color rgba(0, 0, 0, 0)
            border-top-color rgba(0, 0, 0, 0)

        >>> .el-table .sort-caret.ascending
            border-bottom-color $color_white

        >>> .el-table .sort-caret.descending
            border-top-color $color_white

        >>> .rbv-table-header-row th:first-child
            padding-left 15px

        >>> .el-table td:first-child
            padding-left 15px

        .ry-color-block
            display inline-block
            vertical-align middle
            background-color rgba(255, 255, 255, 0)
            margin-right 8px
            font-size 18px

        .com-ranking-by-vehicle-main
            height 100%

            .com-ranking-by-vehicle-table-box-main
                overflow-y hidden
                overflow-x auto

            .com-ranking-by-vehicle-table-box
                padding 0 0 0
                height 227px
                overflow hidden
                min-width 595px

                .com-ranking-by-vehicle-text-box
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor default

                >>> .el-table .cell, .el-table th div,
                >>> .el-table--border td:first-child .cell,
                >>> .el-table--border th:first-child .cell
                    padding-left 1px

                >>> .el-table .cell,
                >>> .el-table th div
                    padding-right 1px

                >>> .el-table th .cell
                    white-space nowrap

        >>> .el-dialog__body
            padding-top 0
            padding-bottom 30px

        >>> .el-dialog__header
            border-bottom 2px solid $color_gray_third

        /*scroll 竖直方向的滚动条显示 start*/

        .com-ranking-by-vehicle-table-box-main
            height 227px
            overflow-y auto !important

            .com-ranking-by-vehicle-table-box
                height auto

        >>> .el-table--scrollable-x .el-table__body-wrapper
            3overflow-x hidden

        >>> .el-table--scrollable-x .el-table__body-wrapper
            overflow-x hidden

    /*scroll 竖直方向的滚动条显示 end*/

    .com-ranking-by-vehicle-scroll::-webkit-scrollbar
        width: 10px;
        height 10px

    .com-ranking-by-vehicle-scroll::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;

    .com-ranking-by-vehicle-scroll::-webkit-scrollbar-thumb
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

    >>> .table-head {
        display inline-block
        white-space nowrap
        overflow: hidden
        text-overflow: ellipsis
        vertical-align middle
        max-width: calc(100% - 24px)
    }
</style>
