import { render, staticRenderFns } from "./ComTotalChart.vue?vue&type=template&id=dd5f197e&scoped=true&"
import script from "./ComTotalChart.vue?vue&type=script&lang=js&"
export * from "./ComTotalChart.vue?vue&type=script&lang=js&"
import style0 from "./ComTotalChart.vue?vue&type=style&index=0&id=dd5f197e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd5f197e",
  null
  
)

export default component.exports